import * as React from "react";
import {useContext, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Button, Dropdown, Form, Header, Input, List, Loader, Message, Modal, Label} from "semantic-ui-react";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {GlyftyApi} from "../../services/GlyftyApi";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {Purchase} from "../../common/Purchase";
import {Link} from "react-router-dom";
import {ReactComponent as GlyftyIcon} from "../../img/glyfty-grey.svg";
import {OrgPageParams} from "../../components/OrgPageRouter";

const MailLink: React.FC<{ email: string }> = (props) => {
    return <a href={"mailto:" + props.email} {...props}>{props.children}</a>
};

const distinct = (value, index, arr) => index === 0 || value !== arr[index - 1];

const PurchaseModalContent: React.FC<{ purchase: Purchase, onClose: () => void }> = ({purchase, onClose}) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const events = purchase.events || [];
    const userIds = events.map(e => e.userId).sort().filter(distinct);
    const userDetails = useAsync<{ name: string, email: string }[]>(async () => {
        return GlyftyApi.getInfoForUsers(authContext, userIds);
    }, []);

    if (userDetails.loading) {
        return <Loader active={true}/>
    }

    if (userDetails.error) {
        return <Message error content={userDetails.error.message}/>
    }

    if (!userDetails.result) {
        return <Message error content="Unexpected error"/>
    }

    const usernameMap = userIds.reduce((map, id, index) => ({...map, [id]: userDetails.result![index]}), {});

    return <Modal header="Purchase Details" open={true} onActionClick={onClose} actions={[{key: "ok", name: "ok", content: "OK", positive: true}]}
                  content={<Modal.Content>
                      <div>Glyfty purchase id: {purchase.id}</div>
                      <div>Stripe purchase id: {purchase.stripePurchaseId || "INCOMPLETE"}</div>
                      <div>Customer email: {purchase.email}</div>
                      <div>Remaining: {purchase.remaining}</div>
                      <Header>Redemptions ({purchase.events.length})</Header>
                      {
                          purchase.events.map((e, index) => <div key={index}>
                              {new Date(e.date).toLocaleString()} by {usernameMap[e.userId].name} ({usernameMap[e.userId].email})
                          </div>)
                      }
                  </Modal.Content>}>
    </Modal>
};

function redeem_summary(purchase: Purchase) {
    if (!purchase.stripePurchaseId) {
        return <Label className="purchase-incomplete">Incomplete</Label>
    }
    const redeemed = purchase.events ? purchase.events.length : 0;
    const total = purchase.remaining + redeemed;
    return <span className="purchase-redeem-summary">{redeemed} of {total} redeemed</span>
}

const PurchaseLineItem = ({purchase, filterBy, orgId}: { orgId: string, purchase: Purchase, filterBy: (email: string) => void }) => {
    const [showDetail, setShowDetail] = useState(false);

    function close() {
        setShowDetail(false);
    }

    function open(e) {
        setShowDetail(true);
        e.preventDefault();
    }

    return <List.Item>
        {showDetail && <PurchaseModalContent purchase={purchase} onClose={close}/>}
        <Dropdown icon="cog" pointing className="purchase hamburger">
            <Dropdown.Menu>
                <Dropdown.Item icon="info circle" content="View Details" onClick={open}/>
                <Dropdown.Item icon="send" content={<MailLink email={purchase.email}>Email Customer</MailLink>}/>
                <Dropdown.Item icon="filter" content="Filter by this Email" onClick={() => filterBy(purchase.email)}/>
                <Dropdown.Item icon={<GlyftyIcon className="glyfty-icon"/>} content="Reissue Voucher" as={Link} to={`/org/${orgId}/purchase/reissue/${purchase.id}`}/>
                <Dropdown.Item icon="fire" content="Redeem..." as={Link} to={"/redeem/" + purchase.id}/>
            </Dropdown.Menu>
        </Dropdown>
        <List.Header><Button basic className="purchase-email" onClick={open}>{purchase.email}</Button></List.Header>
        <List.Content>
            <span className="purchase-date">{new Date(purchase.created).toLocaleString("en-gb")}</span>
            {redeem_summary(purchase)}
        </List.Content>
    </List.Item>
};

type VoucherPurchaseResultSet = { Items: Purchase[], LastEvaluatedKey: any };

export const PurchaseListPage = ({match}: RouteComponentProps<OrgPageParams & { voucherId?: string }>) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const [email, setEmail] = useState("");
    const [appendMode, setAppendMode] = useState();
    const [moreAvailable, setMoreAvailable] = useState();
    const [purchases, setPurchases] = useState<VoucherPurchaseResultSet>({
        Items: [],
        LastEvaluatedKey: undefined
    });

    const voucherId = match.params.voucherId;

    const asyncFetch = useAsyncCallback<VoucherPurchaseResultSet>((email?: string, startKey?: object) => {
        console.log("Fetch, email=", email, "start key=", startKey);
        return GlyftyApi.getPurchases(authContext, match.params.orgId, voucherId, email, startKey);
    });

    useAsync(async () => {
        const r = await asyncFetch.execute();
        setPurchases(r);
        setMoreAvailable(r.LastEvaluatedKey);
    }, [/* bit of a hack, this should contain asyncFetch but then it loops forever */]);

    function updateEmail(e, data) {
        setEmail(data.value);
    }

    async function doFetch(email: string, append: boolean) {
        setAppendMode(append);
        setMoreAvailable(false);
        try {
            const result = await asyncFetch.execute(email, append ? purchases.LastEvaluatedKey : undefined);
            setPurchases({
                Items: append ? purchases.Items.concat(result.Items) : result.Items,
                LastEvaluatedKey: result.LastEvaluatedKey
            });
            setMoreAvailable(result.LastEvaluatedKey);
        } finally {
            setAppendMode(false);
        }
    }

    function filter() {
        return doFetch(email, false);
    }

    function filterBy(email: string) {
        setEmail(email);
        return doFetch(email, false);
    }

    function clear() {
        setEmail("");
        return doFetch("", false);
    }

    function more() {
        return doFetch(email, true);
    }

    const purchase_table = <List divided relaxed>
        {purchases.Items.map(purchase => <PurchaseLineItem orgId={match.params.orgId} key={purchase.id} purchase={purchase} filterBy={filterBy}/>)}
    </List>;

    return <>
        <Form>
            <Form.Group>
                <Form.Field as={Input} name="email" placeholder="Filter by customer email" value={email} onChange={updateEmail}/>
                <Button icon="filter" content="Filter" onClick={filter}/>
                <Button onClick={clear}>Clear</Button>
            </Form.Group>
        </Form>
        {
            (asyncFetch.loading && !appendMode) ?
                <Loader active={true}/> :
                asyncFetch.error ?
                    <Message error content={asyncFetch.error.message}/> :
                    purchases.Items.length ?
                        purchase_table :
                        <div>No purchases found</div>
        }
        {moreAvailable && <Button basic loading={asyncFetch.loading} content="Load More" onClick={more}/>}
    </>
};