import * as React from "react";
import {useContext} from "react";
import {Label, Segment} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
import {VoucherCreateEdit, VoucherCreateEditMode} from "../../components/VoucherCreateEdit";
import {GlyftyApi} from "../../services/GlyftyApi";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {Voucher} from "../../common/Voucher";

type VoucherCreateParams = {
    orgId: string
}

export const VoucherCreatePage = (props: RouteComponentProps<VoucherCreateParams>) => {
    const authContext = useContext<AuthContextType>(AuthContext);

    const voucher: Voucher = {
        id: "",
        orgId: props.match.params.orgId,
        title: "",
        description_short: "",
        description_purchase: "",
        description_recipient: "",
        count: 1,
        cost: 0,
        duration: 0
    };

    function create(voucher: Voucher) {
        return GlyftyApi.createVoucher(authContext, voucher).then(() => props.history.push("../vouchers"));
    }

    function cancel() {
        props.history.push("../vouchers");
    }

    return <Segment>
        <Label attached="top">Create Voucher</Label>
        <VoucherCreateEdit mode={VoucherCreateEditMode.CREATE} voucher={voucher} onSubmit={create} onCancel={cancel}/>
    </Segment>

};