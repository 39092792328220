import {CognitoUserAttribute} from "amazon-cognito-identity-js";
import {FieldError} from "react-hook-form/dist/types";
import {AuthContextType} from "../providers/AuthContextProvider";
import {Voucher} from "../common/Voucher";

export const IMAGE_SERVER = `https://glyfty-images.s3-eu-west-1.amazonaws.com/`;

function stage() {
    switch (window.location.hostname) {
        case "www.glyfty.com":
            return "l";
        case "test.glyfty.com":
            return "t";
        default:
            return "d";
    }
}

export const STAGE_NAME = stage();

export function isLive() {
    return STAGE_NAME === "l";
}

export function isTestOrLive() {
    return isLive() || STAGE_NAME === "t";
}

export const STRIPE_OAUTH_CLIENT_ID = isLive() ? "ca_F3LIyYYuWonnYJdtBJ63PHqJ8SnS3AKb" : "ca_F3LINejnPkkkHTrd6feNGMYqhAKX5lW7";
export const STRIPE_PUBLIC_KEY = isLive() ? "pk_live_8vcWSzYGQIXgm1mzkSNLMZ1d00njnOjcov" : "pk_test_K7TTfAq7ggCcVF5vU5pXe1m200JantWIzU";

export function g_fetch_idtoken(fn: string, idToken: string, init?: RequestInit) {
    return fetch(`https://api.glyfty.com/${STAGE_NAME}/${fn}`, {
        ...init,
        headers: {
            Authorization: idToken,
            ...(init ? init.headers : {})
        }
    }).then(r => {
        return r.json();
    }).then(r => {
        if (r.errorMessage) {
            throw new Error(r.errorMessage);
        }
        return r;
    });
}

export function g_fetch_auth(fn: string, authContext: AuthContextType, init?: RequestInit) {
    return authContext.actions.currentSession().then(session => g_fetch_idtoken(fn, session.getIdToken().getJwtToken(), init));
}

export function g_fetch_no_auth(fn: string, init?: RequestInit) {
    return fetch(`https://api.glyfty.com/${STAGE_NAME}/${fn}`, {
        ...init,
        headers: {
            Authorization: "blah",
            ...(init ? init.headers : {})
        }
    }).then(r => {
        return r.json();
    }).then(r => {
        if (r.errorMessage) {
            throw new Error(r.errorMessage);
        }
        return r;
    });
}


export function findAttribute(attrs: CognitoUserAttribute[], name: string, strict: boolean = true) {
    for (const a of attrs) {
        if (a.getName() === name) {
            return a.getValue();
        }
    }
    if (strict) {
        throw new Error("Attribute not found!");
    }
    return "";
}

export function findSub(attrs: CognitoUserAttribute[]) {
    return findAttribute(attrs, "sub");
}

export function logoUrl(orgId: string) {
    if (isTestOrLive()) {
        // share images between test and live
        return `${IMAGE_SERVER}${orgId}.png`;
    }
    return `${IMAGE_SERVER}${STAGE_NAME}/${orgId}.png`;
}

export function printableVoucherUrl(purchaseId: string) {
    return `${IMAGE_SERVER}printable/${purchaseId}.jpg`;
}

export function templateUrl(orgId: string, templateId: string) {
    if (isTestOrLive()) {
        // share images between test and live
        return `${IMAGE_SERVER}${orgId}/${templateId}`;
    }
    return `${IMAGE_SERVER}${STAGE_NAME}/${orgId}/${templateId}`;
}

export function describeError(error?: FieldError) {
    if (error) {
        return error.type === "pattern" ? "This field is not valid" : error.message;
    }
}

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function absoluteUrl(path) {
    return decodeURI(new URL(path, document.baseURI).href)
}

export function expiryDate(voucher: Voucher) {
    if (!voucher.duration) {
        return "Voucher does not expire";
    }
    const now = new Date().getTime() + (voucher.duration ? voucher.duration * 24 * 3600000 : 0);
    return "Voucher expires on " + new Date(now).toLocaleDateString("en-gb");
}


