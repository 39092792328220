import * as React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Button, Form, Input, InputOnChangeData, Label, Message, Segment} from "semantic-ui-react";
import {useDropzone} from "react-dropzone";
import {GlyftyApi} from "../../services/GlyftyApi";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import useForm from "react-hook-form";
import {useAsyncCallback} from "react-async-hook";
import {describeError} from "../../services/util";
import {OrgPageParams} from "../../components/OrgPageRouter";

export const TemplateCreatePage = ({match, history}: RouteComponentProps<OrgPageParams>) => {
    const authContext = useContext<AuthContextType>(AuthContext);

    const [file, setFile] = useState();
    const [preview, setPreview] = useState();
    // const [imageSizeWarning, setImageSizeWarning] = useState();
    const [imageTypeError, setImageTypeError] = useState();

    const onDrop = useCallback(acceptedFiles => {
        const acceptedFile = acceptedFiles[0];
        console.log("File:", acceptedFile);
        if (acceptedFile.type === "image/png" || acceptedFile.type === "image/jpeg") {
            setImageTypeError(undefined);
            setFile(acceptedFile);
            setPreview(URL.createObjectURL(acceptedFile));
        } else {
            setImageTypeError("Template must be a PNG or JPEG image");
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false});

    const {orgId} = match.params;

    const {register, getValues, errors, handleSubmit, setValue} = useForm();
    const asyncSubmit = useAsyncCallback(handleSubmit(createTemplate));

    useEffect(() => {
        register({name: "name"}, {required: "This field is required"});
    }, [register]);

    async function createTemplate() {
        const s3info=await GlyftyApi.createTemplateUploadUrl(authContext, orgId);
        await fetch(s3info.signedRequest, {
            method: "PUT",
            headers: {
                "Content-Type": file.type,
                "Cache-Control": "max-age=300"
            },
            body: file
        });

        await GlyftyApi.createTemplate(authContext, orgId, s3info.id, getValues().name);
        history.push("../templates");
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    function cancel() {
        history.push("../templates");
    }

    function imageCheck(/*img*/) {
        // if (img) {
        //     img.onload = function () {
        //         if (img.naturalWidth !== 220) {
        //             setImageSizeWarning("Your logo should be 220 pixels wide and approximately 230 pixels high for best results");
        //         } else {
        //             setImageSizeWarning(undefined);
        //         }
        //     }
        // }
    }

    return <>
        <Segment>
            <Label attached="top">Template Image</Label>
            <p>Image type PNG and JPEG are supported but we recommend using PNG for best results.</p>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    preview ? <div><img ref={imageCheck} alt="preview" src={preview}/></div> : isDragActive ?
                        <div className="dropzone active">Drop logo here ...</div> :
                        <div className="dropzone">
                            <div>Click to choose image file or drop here</div>
                            <div><Button>Choose Image</Button></div>
                        </div>
                }
            </div>
            {imageTypeError && <Message error content={imageTypeError}/>}
        </Segment>

        <Segment>
            <Label attached="top">Template Details</Label>
            <Form success={asyncSubmit.status === "success"} error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
                <Form.Field control={Input} required name="name" label="Name"
                            placeholder='Name of the template' onChange={handleChange} error={describeError(errors.name)}/>

                {asyncSubmit.error && <Message error>{asyncSubmit.error.message}</Message>}

                <Form.Group>
                    <Form.Button loading={asyncSubmit.loading} color="green">Create</Form.Button>
                    <Form.Button disabled={asyncSubmit.loading} type="reset" onClick={cancel}>Cancel</Form.Button>
                </Form.Group>
            </Form>
        </Segment>
    </>
};
