import * as React from "react";
import {useContext, useState} from "react";
import {RouteComponentProps} from "react-router";
import {AuthFormMode} from "../../components/AuthForm";
import {AuthRequired} from "../../components/AuthRequired";
import {Button, Card, Container, Loader, Message, Modal, Statistic} from "semantic-ui-react";
import {GlyftyApi} from "../../services/GlyftyApi";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {isLive} from "../../services/util";
import {useAsync} from "react-async-hook";
import {Purchase} from "../../common/Purchase";

type RedeemPageParams = {
    purchaseId: string,
};

export const RedeemPage = ({match}: RouteComponentProps<RedeemPageParams>) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const [redeemComplete, setRedeemComplete] = useState(false);

    const asyncFetch = useAsync<Purchase>(async () => GlyftyApi.getPurchase(match.params.purchaseId), [match.params.purchaseId]);

    if (asyncFetch.loading) {
        return <Loader active={true}/>
    }
    if (asyncFetch.error) {
        return <Message error content={asyncFetch.error.message}/>
    }

    const purchase = asyncFetch.result!;
    const last = purchase.remaining === 0;

    async function redeem() {
        await GlyftyApi.redeem(authContext, match.params.purchaseId);
        purchase.remaining--;
        // HACK ALERT - should get new value from server
        purchase.events.push({
            date: new Date().getTime(),
            userId: "unknown"
        });
        setRedeemComplete(true);
        setTimeout(() => {
            setRedeemComplete(false);
        }, 2500);
    }

    function click(e) {
        if (e.target.name === "ok") {
            return redeem();
        }
    }

    return <AuthRequired mode={AuthFormMode.login}>
        <Container className="main">
            {!isLive() && <Message icon="warning sign" warning content="This is a test mode voucher and has not been purchased"/>}
            <Card.Group centered>
                <Card>
                    <Statistic size="huge" color={last ? "red" : "green"}>
                        <Statistic.Value>
                            {purchase.remaining}
                        </Statistic.Value>
                        <Statistic.Label>
                            remaining
                        </Statistic.Label>
                    </Statistic>
                    <Card.Content>
                        <Card.Header>
                            {last ? <></> :
                                <Modal trigger={<Button fluid disabled={asyncFetch.loading || redeemComplete}
                                                        color={redeemComplete ? "green" : undefined}>{redeemComplete ? "Done!" : "Redeem"}</Button>}
                                       basic size="fullscreen"
                                       header="Redeem Voucher" content="Are you sure?" onActionClick={click}
                                       actions={["Cancel", {key: "ok", name: "ok", content: "OK", positive: true}]}
                                />}
                        </Card.Header>
                        {
                            purchase.events.length > 0 && <div className="purchase-redeem-uses">
                                <div>Previous uses</div>
                                {
                                    purchase.events.map((e, index) => <div key={index}>
                                        {new Date(e.date).toLocaleString()}
                                    </div>)
                                }
                            </div>
                        }
                    </Card.Content>
                </Card>
            </Card.Group></Container>
    </AuthRequired>
};