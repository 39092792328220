import * as React from "react";
import {useContext, useEffect} from "react";
import {RouteComponentProps} from "react-router";
import {OrgPageParams} from "../../components/OrgPageRouter";
import {Form, InputOnChangeData, Label, Message, Radio, Segment, Input, Checkbox} from "semantic-ui-react";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {useAsyncCallback} from "react-async-hook";
import useForm from "react-hook-form";
import {ChargeStructure, ChargeType} from "../../common/Organisation";
import {describeError} from "../../services/util";
import {GlyftyApi} from "../../services/GlyftyApi";

export const OrgAdminPage = ({match}: RouteComponentProps<OrgPageParams>) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const asyncEnableOrg = useAsyncCallback(updateOrgEnabled);

    const org = authContext.organisations.find(o => o.id === match.params.orgId);
    if (!org) {
        throw new Error("Organisation not found");
    }

    const billing: ChargeStructure = org.billing ? org.billing : {
        type: ChargeType.NONE,
        percentage: 0,
        minFee: 0
    };

    const {type, percentage, minFee} = billing;

    const defaultValues = {enabled: org.enabled, type, percentage, minFee};

    const {register, getValues, errors, handleSubmit, setValue, reset: resetForm} = useForm({defaultValues});

    useEffect(() => {
        register({name: "type"});
        register({name: "percentage"});
        register({name: "minFee"});
    }, [register]);

    const asyncSubmit = useAsyncCallback(handleSubmit(updateBilling));

    async function updateOrgEnabled(enabled: boolean) {
        await GlyftyApi.enableOrg(authContext, org!.id, enabled);
    }

    async function updateBilling() {
        const {minFee, percentage, ...props} = getValues();
        if ( Math.floor(minFee) !== Number(minFee) ) {
            throw new Error("Minimum fee must be a whole number of pence");
        }
        await GlyftyApi.updateOrgBilling(authContext, org!.id, {
            ...props,
            minFee: Number.parseInt(minFee),
            percentage: Number.parseFloat(percentage)
        } as ChargeStructure);
    }

    function handleRadioChange(type: ChargeType) {
        console.log("Set charge type: ", type);
        setValue("type", type);
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    function reset() {
        setValue("type", billing.type);
        setValue("percentage", billing.percentage);
        setValue("minFee", billing.minFee);
        resetForm();
    }


    function changeEnabled(event: React.FormEvent<HTMLInputElement>, data) {
        asyncEnableOrg.execute(data.checked).then(() => org!.enabled = data.checked);
    }


    return <>
        <div>
            <Checkbox label="Enabled" defaultChecked={org.enabled} toggle onChange={changeEnabled}/>
            {asyncEnableOrg.error && <span className="inline-error">{asyncEnableOrg.error.message}</span>}
        </div>
        <Segment>
        <Label attached="top">Organisation Fee Settings</Label>

        <Form success={asyncSubmit.status === "success"} error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
            <Form.Group>
                {
                    Object.values(ChargeType).map(t =>
                        <Form.Field key={t} control={Radio} name="type" label={t}
                                    defaultChecked={billing.type === t} onChange={() => handleRadioChange(t)}/>
                    )
                }
            </Form.Group>
            <Form.Field control={Input} name="percentage" label="Percentage" defaultValue={billing.percentage}
                        placeholder='Percentage to charge' onChange={handleChange} error={describeError(errors.percentage)}/>
            <Form.Field control={Input} name="minFee" label="Minimum (in pence)" defaultValue={billing.minFee}
                        placeholder='Minimum fee to charge' onChange={handleChange} error={describeError(errors.minFee)}/>

            <Message success content="Updated successfully"/>
            {asyncSubmit.error && <Message error>{asyncSubmit.error.message}</Message>}

            <Form.Group>
                <Form.Button loading={asyncSubmit.loading} color="green">Update</Form.Button>
                <Form.Button disabled={asyncSubmit.loading} type="reset" onClick={reset}>Reset</Form.Button>
            </Form.Group>
        </Form>
    </Segment></>
};