export enum VoucherTemplateItemType {
    QRCODE = "QRCODE",
    VOUCHER_ID = "VOUCHER_ID",
    ISSUE_DATE = "ISSUE_DATE",
    EXPIRY_DATE = "EXPIRY_DATE",
    GREETING = "GREETING",
    CUSTOM_TEXT = "CUSTOM_TEXT"
}

export type VoucherTemplateItem = {
    type: VoucherTemplateItemType,
    value?: string,
    size?: number,
    colour?: string;
    lineLength?: number,
    x: number,
    y: number
}