import * as React from "react"
import {useContext} from "react"
import {Container, Header, Segment, Button, Icon, Card, List, Statistic} from "semantic-ui-react";
import {AuthContext, AuthContextType, AuthState} from "../providers/AuthContextProvider";
import {Link} from "react-router-dom";

export const PricingPage = () => {
    const authContext = useContext<AuthContextType>(AuthContext);

    const link = authContext.state === AuthState.auth ? "/" : "/register";

    // noinspection HtmlUnknownTarget
    return <div>
        <Segment className="pricing-detail first" vertical>
            <Container>
                <Header>
                    <Header.Content>Partnered with Stripe</Header.Content>
                    <a href="https://stripe.com/gb/pricing"><img src="/stripe.svg" alt="Stripe" className="stripe-logo"/></a>
                    <Header.Subheader>We pass on the Stripe costs, so you'll pay <a href="https://stripe.com/gb/pricing">
                        the Stripe standard fees</a> on each voucher
                        purchase
                    </Header.Subheader>
                </Header>
            </Container>
        </Segment>
        <Segment className="pricing-detail" vertical>
            <Container>
                <Header>
                    <Header.Content>Free to charities</Header.Content>
                    <Header.Subheader>
                        As a charity you won't pay any more than the <a href="https://stripe.com/gb/pricing">Stripe fees</a>
                    </Header.Subheader>
                </Header>
            </Container>
        </Segment>
        <Segment className="pricing-detail" vertical>
            <Container>
                <Header>
                    <Header.Content>Simple and transparent Glyfty fees</Header.Content>
                    <Header.Subheader>
                        We charge a small additional fee to cover our costs, naturally.
                        This is in addition to the <a href="https://stripe.com/gb/pricing">Stripe fees</a>
                    </Header.Subheader>
                </Header>
                <Card.Group centered className="pricing-prices">
                    <Card>
                        <Card.Content>
                            <Card.Header>
                                STANDARD
                            </Card.Header>
                            <Card.Description>
                                <Statistic>
                                    <Statistic.Value>1.2%</Statistic.Value>
                                    <Statistic.Label>plus 20p</Statistic.Label>
                                </Statistic>
                                <List bulleted>
                                    <List.Item>Simple voucher (logo + QR code)</List.Item>
                                    <List.Item>Unlimited organisations (brands)</List.Item>
                                    <List.Item>Up to 5 active vouchers</List.Item>
                                    <List.Item>Purchase receipt by email</List.Item>
                                    <List.Item>Up to 5 users</List.Item>
                                </List>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>
                                PREMIUM
                            </Card.Header>
                            <Card.Description>
                                <Statistic>
                                    <Statistic.Value>2.5%</Statistic.Value>
                                    <Statistic.Label>plus 40p</Statistic.Label>
                                </Statistic>
                                <List bulleted>
                                    <List.Item>Custom voucher templates</List.Item>
                                    <List.Item>Unlimited organisations (brands)</List.Item>
                                    <List.Item>Unlimited active vouchers</List.Item>
                                    <List.Item>Purchase receipt by email</List.Item>
                                    <List.Item>Unlimited users</List.Item>
                                </List>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Card.Group>
                <Button color="green" size="large" icon as={Link} to={link}>Get Started <Icon name="arrow right"/></Button>
            </Container>
        </Segment>

    </div>
};