import * as React from "react";
import {useContext, useState} from "react";
import {OrgPageParams} from "../../components/OrgPageRouter";
import {RouteComponentProps} from "react-router";
import {Button, Checkbox, Form, Header, Label, Loader, Message, Segment} from "semantic-ui-react";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {Capability} from "../../common/Capability";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {GlyftyApi} from "../../services/GlyftyApi";
import {GlyftyConfirm} from "../../components/util/GlyftyConfirm";

const CapabilityDescription = {
    [Capability.MODIFY_USERS]: "Add/invite users",
    [Capability.VIEW_USERS]: "View users within organisation",
    [Capability.VIEW_VOUCHERS]: "View voucher details",
    [Capability.VIEW_TEMPLATES]: "View template details",
    [Capability.VIEW_PURCHASES]: "View purchases",
    [Capability.REDEEM_VOUCHER]: "Redeem voucher",
    [Capability.MODIFY_VOUCHERS]: "Create or update vouchers",
    [Capability.MODIFY_TEMPLATES]: "Create or update templates",
    [Capability.MODIFY_ORGANISATION]: "Modify organisation details",
    [Capability.OWNER]: "Full control (owner)",
};

type CapabilityEditProps = {
    existing: string[],
    onChange(capabilities: string[]): void
};

export const CapabilityEdit = (props: CapabilityEditProps) => {
    const existing = props.existing.reduce((sum, c) => ({...sum, [c]: true}), {});

    const [capabilityMap, enableCapability] = useState(existing);

    function changeCapability(e, d) {
        const newValue = {
            ...capabilityMap,
            [d.name]: d.checked
        };
        enableCapability(newValue);
        const caps = Object.keys(newValue).filter(c => newValue[c]);
        console.log("Updating caps: ", caps);
        props.onChange(caps);
    }

    return <Segment><Label attached="top">Capabilities</Label>
        {
            Object.keys(Capability).filter(c => c !== Capability.SUPERUSER).map(c => {
                const ownerSelected = c !== Capability.OWNER && capabilityMap[Capability.OWNER];
                const checked = (capabilityMap[c] || ownerSelected);
                return <div key={c + checked}>
                    <Checkbox disabled={ownerSelected} className="capability" name={c}
                              label={<label>{CapabilityDescription[c]}</label>} checked={checked}
                              onChange={changeCapability}/>
                </div>
            })
        }
    </Segment>

};

export const OrgEditUserPage = (props: RouteComponentProps<OrgPageParams & { userId: string }>) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const {orgId, userId} = props.match.params;

    const [capabilities, setCapabilities] = useState({});

    const asyncLoad = useAsync(async () => {
        return await GlyftyApi.getOrgUser(authContext, orgId, userId);
    }, [orgId, userId]);

    const asyncSubmit = useAsyncCallback(editUser);
    const asyncRemoveUser = useAsyncCallback(removeUser);

    async function editUser() {
        await GlyftyApi.updateUser(authContext, orgId, userId, {capabilities});
        props.history.goBack();
    }

    async function removeUser() {
        await GlyftyApi.removeUserFromOrg(authContext, orgId, userId);
        props.history.goBack();
    }

    function cancel(e) {
        e.preventDefault();
        props.history.goBack();
    }

    if (asyncLoad.loading) {
        return <Loader active={true}/>
    }
    if (asyncLoad.error) {
        return <Message error content={asyncLoad.error.message}/>
    }

    return <>
        <GlyftyConfirm floated="right" color="red" loading={asyncRemoveUser.loading} basic icon="remove user" prompt="Remove User" header={`Remove ${asyncLoad.result.name}`}
                       content={"Are you sure you want to remove this user's access to this organisation?"} onConfirm={asyncRemoveUser.execute}/>

        <Header as='h3'>
            <Header.Content>
                {asyncLoad.result.name}
                <Header.Subheader>{asyncLoad.result.email}</Header.Subheader>
            </Header.Content>
        </Header>

        {asyncRemoveUser.error && <Message error content={asyncRemoveUser.error.message}/>}

        <Form success={asyncSubmit.status === "success"} error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
            <CapabilityEdit existing={asyncLoad.result.capabilities} onChange={setCapabilities}/>

            {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}

            <Form.Group>
                <Form.Field control={Button} loading={asyncSubmit.loading} type="submit" color="green">Update</Form.Field>
                <Form.Field control={Button} disabled={asyncSubmit.loading} type="cancel" onClick={cancel}>Cancel</Form.Field>
            </Form.Group>
        </Form></>
};