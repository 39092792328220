import * as React from "react";
import {RouteComponentProps} from "react-router";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {VoucherTemplateItem, VoucherTemplateItemType} from "../../common/VoucherTemplateItem";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {GlyftyApi} from "../../services/GlyftyApi";
import {Button, Form, Loader, Message} from "semantic-ui-react";
import {TemplateParamEditor, TemplateRender} from "../../components/TemplateConfiguration";
import {VoucherTemplate} from "../../common/VoucherTemplate";

export const TemplateConfigurePage = (props: RouteComponentProps<{ orgId: string, templateId: string }>) => {
    const {orgId, templateId} = props.match.params;
    const authContext = React.useContext<AuthContextType>(AuthContext);

    const [params, setParams] = React.useState<VoucherTemplateItem[]>([
        {type: VoucherTemplateItemType.QRCODE, x: 100, y: 50},
        {type: VoucherTemplateItemType.VOUCHER_ID, x: 100, y: 230},
        {type: VoucherTemplateItemType.ISSUE_DATE, x: 100, y: 250},
        {type: VoucherTemplateItemType.EXPIRY_DATE, x: 100, y: 270},
        {type: VoucherTemplateItemType.GREETING, x: 100, y: 300},
    ]);

    const asyncSubmit = useAsyncCallback(updateTemplate);

    const asyncFetch = useAsync(async () => {
        const templates = await GlyftyApi.getTemplates(authContext, orgId);
        const template = templates.find(t => t.id === templateId);
        if (!template) {
            throw new Error("Template not found");
        }
        if (template.templateItems) {
            setParams(template.templateItems);
        }
        return template
    }, [templateId]);

    if (asyncFetch.loading) {
        return <Loader active={true}/>
    }

    if (asyncFetch.error) {
        return <Message error content={asyncFetch.error.message}/>;
    }

    function cancel() {
        props.history.goBack();
    }

    async function updateTemplate() {
        const updated: VoucherTemplate = {
            ...template,
            templateItems: params
        };
        await GlyftyApi.updateTemplate(authContext, updated);
        props.history.push("../../templates");
    }

    const template = asyncFetch.result!;

    return <Form loading={asyncSubmit.loading} onSubmit={asyncSubmit.execute} error={!!(asyncSubmit.error)}>
        <TemplateParamEditor onChange={p => setParams([...p])} params={params}/>
        <TemplateRender orgId={orgId} templateId={templateId} items={params}/>

        <p>
            <Button type="submit" color="green" loading={asyncSubmit.loading} content="Save Changes"/>
            <Button type="button" onClick={cancel} content="Cancel"/>
        </p>
    </Form>
};