import * as React from "react";
import {STAGE_NAME} from "../services/util";

export const SiteBanner = () => {
    return {
        d: <div className="dev-banner">DEVELOPMENT</div>,
        t: <div className="test-banner">
            Test mode: changes you make here will not affect your live vouchers or users.
            At checkout <a href="https://stripe.com/docs/testing#cards">use the Stripe test cards</a>
        </div>,
        l: <></>
        // <div className="beta-banner">Glyfty is currently in beta. Please contact <a href="mailto:info@glyfty.com">info@glyfty.com</a> for more information</div>,
    }[STAGE_NAME]
};