import * as React from "react";
import {useContext} from "react";
import {Link} from "react-router-dom";
import {Route, RouteComponentProps} from "react-router";
import {VoucherListPage} from "../pages/admin/VoucherListPage";
import {Container, Message} from "semantic-ui-react";
import {AuthContext, AuthContextType} from "../providers/AuthContextProvider";
import {MenuBarOrganisations} from "./MenuBarOrganisations";
import {OrgMenu} from "./OrgMenu";
import {OrgUsersPage} from "../pages/admin/OrgUsersPage";
import {OrgAddUserPage} from "../pages/admin/OrgAddUserPage";
import {OrgSettingsPage} from "../pages/admin/OrgSettingsPage";
import {VoucherCreatePage} from "../pages/admin/VoucherCreatePage";
import {VoucherEditPage} from "../pages/admin/VoucherEditPage";
import {Capability} from "../common/Capability";
import {OrgEditUserPage} from "../pages/admin/OrgEditUserPage";
import {OrgDeletePage} from "../pages/admin/OrgDeletePage";
import {UserOrganisation} from "../common/UserOrganisation";
import {CapabilityGuard} from "./CapabilityGuard";
import {OrgAdminPage} from "../pages/admin/OrgAdminPage";
import {isLive} from "../services/util";
import {PurchaseListPage} from "../pages/admin/PurchaseListPage";
import {TemplateListPage} from "../pages/admin/TemplateListPage";
import {TemplateCreatePage} from "../pages/admin/TemplateCreatePage";
import {VoucherTemplatePage} from "../pages/admin/VoucherTemplatePage";
import {TemplateConfigurePage} from "../pages/admin/TemplateConfigurePage";
import {ReissueVoucherPage} from "../pages/admin/ReissueVoucherPage";

class ErrorBoundary extends React.Component<any, { error?: Error }> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    // static getDerivedStateFromError(error) {
    // return {error};
    // }

    componentDidCatch(error, errorInfo) {
        this.setState({error})
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return <Container className="main"><Message icon="warning sign" error={true} header="An error occurred"
                                                        content={<>
                                                            {this.state.error.message}.
                                                            You can try again or <Link to="/">return to the home page</Link>.</>}/></Container>;
        }

        return this.props.children;
    }
}

type OrgPageRouterParams = {
    orgId: string
}

export type OrgPageParams = {
    orgId: string
}

type OrgPageRouteProps = {
    org: UserOrganisation,
    path: string,
    active: string,
    hideNav?: boolean,
    render,
    capability: string[]
}

const OrgPageRoute = (props: OrgPageRouteProps) => {
    return <Route path={props.path} render={matchProps =>
        <>
            {props.hideNav || <>
                <OrgMenu org={props.org} active={props.active}/>
                {isLive() && !props.org.enabled && <div className="org-disabled-warning">This organisation has not been enabled by Glyfty and cannot sell live vouchers.
                    Please contact <a href="mailto:support@glyfty.com">support@glyfty.com</a> to enable it.</div>}
            </>}
            <CapabilityGuard orgId={props.org.id} capabilities={props.capability} alt="You do not have access to this area">
                <props.render {...matchProps}/>
            </CapabilityGuard></>}
    />
};

export const OrgPageRouter = (props: RouteComponentProps<OrgPageRouterParams>) => {
    const orgId = props.match.params.orgId;

    const SafeWrapper: React.FC = () => {
        const authContext = useContext<AuthContextType>(AuthContext);
        const org = authContext.organisations.find(o => o.id === orgId);

        if (!org) {
            throw new Error("You do not have access to this organisation or it does not exist");
        }

        return <Container className="main">
            <OrgPageRoute org={org} path="/org/:orgId/vouchers" active="vouchers"
                          render={VoucherListPage} capability={[Capability.VIEW_VOUCHERS, Capability.MODIFY_VOUCHERS]}/>

            <OrgPageRoute org={org} path="/org/:orgId/voucher/create" active="vouchers"
                          render={VoucherCreatePage} hideNav capability={[Capability.MODIFY_VOUCHERS]}/>

            <OrgPageRoute org={org} path="/org/:orgId/voucher/edit/:voucherId" active="vouchers"
                          render={VoucherEditPage} hideNav capability={[Capability.MODIFY_VOUCHERS]}/>

            <OrgPageRoute org={org} path="/org/:orgId/voucher/template/:voucherId" active="vouchers"
                          render={VoucherTemplatePage} hideNav capability={[Capability.MODIFY_VOUCHERS]}/>

            <OrgPageRoute org={org} path="/org/:orgId/voucher/purchases/:voucherId" active="vouchers"
                          render={PurchaseListPage} hideNav capability={[Capability.VIEW_PURCHASES]}/>

            <OrgPageRoute org={org} path="/org/:orgId/purchases" active="purchases"
                          render={PurchaseListPage} capability={[Capability.VIEW_PURCHASES]}/>

            <OrgPageRoute org={org} path="/org/:orgId/purchase/reissue/:purchaseId/" active="purchases"
                          render={ReissueVoucherPage} hideNav capability={[Capability.REDEEM_VOUCHER]}/>

            <OrgPageRoute org={org} path="/org/:orgId/templates" active="templates"
                          render={TemplateListPage} capability={[Capability.VIEW_TEMPLATES]}/>

            <OrgPageRoute org={org} path="/org/:orgId/template/create" active="templates"
                          render={TemplateCreatePage} capability={[Capability.MODIFY_TEMPLATES]}/>

            <OrgPageRoute org={org} path="/org/:orgId/template/configure/:templateId" active="templates"
                          render={TemplateConfigurePage} hideNav capability={[Capability.MODIFY_TEMPLATES]}/>

            <OrgPageRoute org={org} path="/org/:orgId/users" active="users"
                          render={OrgUsersPage} capability={[Capability.VIEW_USERS, Capability.MODIFY_USERS]}/>

            <OrgPageRoute org={org} path="/org/:orgId/user/create" active="users"
                          render={OrgAddUserPage} hideNav capability={[Capability.MODIFY_USERS]}/>

            <OrgPageRoute org={org} path="/org/:orgId/user/edit/:userId" active="users"
                          render={OrgEditUserPage} hideNav capability={[Capability.MODIFY_USERS]}/>

            <OrgPageRoute org={org} path="/org/:orgId/settings" active="settings"
                          render={OrgSettingsPage} capability={[Capability.MODIFY_ORGANISATION]}/>

            <OrgPageRoute org={org} path="/org/:orgId/admin" active="admin"
                          render={OrgAdminPage} capability={[Capability.SUPERUSER]}/>

            <OrgPageRoute org={org} path="/org/:orgId/delete" active="settings"
                          render={OrgDeletePage} hideNav capability={[Capability.OWNER]}/>

        </Container>
    };

    return <ErrorBoundary>
        <MenuBarOrganisations history={props.history} orgId={orgId}/>
        <SafeWrapper/>
    </ErrorBoundary>;
};