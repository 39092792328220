import {VoucherTemplateItem, VoucherTemplateItemType} from "./VoucherTemplateItem";

// noinspection DuplicatedCode
export function wrap(lines: string[], limit: number = 40): string[] {
    const result: string[] = [];
    for (const l of lines) {
        if (l.length < limit) {
            result.push(l);
        } else {
            const words = l.split(" ");
            let c = 0;
            let str = "";
            for (const w of words) {
                if (c + w.length < limit) {
                    str += w + " ";
                } else {
                    result.push(str);
                    str = w + " ";
                }
                c = str.length;
            }
            if (c) {
                result.push(str);
            }
        }
    }
    return result;
}

type GroupedParams = { [index: string]: VoucherTemplateItem[] }

// this isn't used because it's all or nothing - either use all template defaults, or use all custom configuration
export function merge_template_params(currentParams: VoucherTemplateItem[], updatedParams: VoucherTemplateItem[]): VoucherTemplateItem[] {
    function reducer(grouped: any, item): GroupedParams {
        if (!grouped[item.type]) {
            grouped[item.type] = [];
        }
        grouped[item.type].push(item);
        return grouped;
    }

    const groupedCurrentParams = currentParams.reduce(reducer, {});
    const groupedUpdatedParams = updatedParams.reduce(reducer, {});

    const groupedMergedParams: GroupedParams = {};
    for (const type of Object.keys(VoucherTemplateItemType)) {
        const currentCount = groupedCurrentParams[type] ? groupedCurrentParams[type].length : 0;
        const updatedCount = groupedUpdatedParams[type] ? groupedUpdatedParams[type].length : 0;
        const count = Math.max(currentCount, updatedCount);

        groupedMergedParams[type] = [];
        for (let n = 0; n < count; n++) {
            if (n < updatedCount) {
                // updated item exists
                if (n < currentCount) {
                    // and current item exists
                    groupedMergedParams[n] = {
                        ...groupedCurrentParams[type][n],
                        ...groupedUpdatedParams[type][n]
                    }
                } else {
                    // no current item
                    groupedMergedParams[n] = groupedUpdatedParams[type][n]
                }
            } else {
                // no updated item
                groupedMergedParams[n] = groupedCurrentParams[type][n]
            }
        }
    }

    return Object.keys(groupedMergedParams).reduce((flattened, type) => {
        return flattened.concat(groupedMergedParams[type]);
    }, [] as VoucherTemplateItem[])
}

