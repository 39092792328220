import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {OrgPageParams} from "../../components/OrgPageRouter";
import {RouteComponentProps} from "react-router";
import {Button, Form, Input, InputOnChangeData, Message} from "semantic-ui-react";
import {GlyftyApi} from "../../services/GlyftyApi";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import useForm from "react-hook-form";
import {useAsyncCallback} from "react-async-hook";
import {describeError, EMAIL_REGEX} from "../../services/util";
import {CapabilityEdit} from "./OrgEditUserPage";

function emailIsValid(email) {
    return EMAIL_REGEX.test(email)
}

export const OrgAddUserPage = (props: RouteComponentProps<OrgPageParams>) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const {orgId} = props.match.params;

    const {register, getValues, errors, handleSubmit, setValue} = useForm();
    const asyncSubmit = useAsyncCallback(handleSubmit(addUser));

    useEffect(() => {
        register({name: "email"}, {required: "You must enter a valid email", pattern: EMAIL_REGEX});
    }, [register]);

    const [capabilities, setCapabilities] = useState();
    // const [capability, enableCapability] = useState({});
    const [message, setMessage] = useState();

    let timer;

    async function addUser() {
        const {email} = getValues();
        return GlyftyApi.addUserToOrg(authContext, orgId, email, capabilities)
            .then(() => {
                props.history.goBack();
            })
    }

    function cancel(e) {
        e.preventDefault();
        props.history.goBack();
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        const email = value.trim();
        const valid = emailIsValid(email);

        setMessage(undefined);

        if (timer) {
            clearTimeout(timer);
            timer = undefined;
        }
        if (valid) {
            timer = setTimeout(() => {
                GlyftyApi.checkEmail(authContext, email)
                    .then(r => {
                        setMessage(r ? "Email found. This user will have the capabilities given when they next log in" : "Email not found. This user will have the " +
                            "capabilities given when they register. They must use the same email address as provided above when registering");
                    })
            }, 1000);
        }

        setValue(name, value);
    }

    // function changeCapabiliy(e, d) {
    //     enableCapability({
    //         ...capability,
    //         [d.name]: d.checked
    //     });
    // }

    return <Form success={asyncSubmit.status === "success"} error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
        <Form.Field control={Input} required name="email" label="Email address" placeholder='Enter email address' onChange={handleChange}
                    error={describeError(errors.email)}/>

        <CapabilityEdit existing={[]} onChange={setCapabilities}/>

        {message && <Message content={message}/>}

        <Message success content="User added"/>
        {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}

        <Form.Group>
            <Form.Field control={Button} loading={asyncSubmit.loading} type="submit" color="green">Add</Form.Field>
            <Form.Field control={Button} disabled={asyncSubmit.loading} type="cancel" onClick={cancel}>Cancel</Form.Field>
        </Form.Group>
    </Form>
};