import * as React from "react";
import {useContext} from "react";
import {Button, Container, Header, Icon, Loader} from "semantic-ui-react";
import {AdminLayout} from "../layouts/AdminLayout";
import {AuthContext, AuthState} from "../providers/AuthContextProvider";
import {Link, RouteComponentProps} from "react-router-dom";
import {OrgList} from "../components/OrgList";

const Switcher = () => {
    const authContext = useContext(AuthContext);

    switch (authContext.state) {
        case AuthState.loading:
            return <Loader active={true}/>;
        case AuthState.auth:
            if (authContext.organisations.length > 0) {
                return <OrgList orgs={authContext.organisations}/>
            }
            return <>
                <p>
                    You can't create vouchers yet. First you need to create a Glyfty organisation by connecting a Stripe account.
                </p>
                <p>
                    If you are trying to access an existing organisation you may need to ask the owner to add you.
                </p>
                <p>
                    <Button color="green" size="large" icon as={Link} to="/connect">Create Organisation<Icon name="arrow right"/></Button>
                </p>
            </>;
        case AuthState.unauth:
            // noinspection HtmlUnknownTarget
            return <>
                <Header>Welcome to Glyfty</Header>

                <p>Glyfty adds simple voucher functionality to your business or charity.</p>

                <Container>
                    <img src="/howitworks.svg" alt="How it works" className="howitworks"/>
                </Container>

                <p>
                    <Button color="green" size="large" icon as={Link} to="/register">Get Started <Icon name="arrow right"/></Button>
                </p>
            </>
    }
};

export const HomePage = (matchProps: RouteComponentProps<{}>) => {

    return <AdminLayout pricing {...matchProps}>
        <Container className="main">
            <Switcher/>
        </Container>
    </AdminLayout>
};