import React, {useContext} from "react";
import {AuthContext, AuthState} from "../providers/AuthContextProvider";
import {Dropdown, Menu} from "semantic-ui-react";
import {findAttribute} from "../services/util";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";

export const MenuBarRight = (props: RouteComponentProps<{}> & { pricing?: boolean }) => {
    const authContext = useContext(AuthContext);

    const email = findAttribute(authContext.attributes, "email", false);
    const name = findAttribute(authContext.attributes, "name", false);

    function logout() {
        authContext.actions.logout();
        props.history.push("/");
    }

    const dropdown_props: any = function () {
        switch (authContext.state) {
            case AuthState.loading:
                return {
                    loading: true,
                    disabled: true,
                };
            case AuthState.unauth:
                return {
                    as: Link,
                    to: "/signin",
                    text: "Sign In",
                    className: "auth-menu-open"
                };
            case AuthState.auth:
                return {
                };
        }
    }();

    const _props = {...dropdown_props, className: "button circular auth-menu" + (dropdown_props.className ? " " + dropdown_props.className : "")};

    return <Menu.Menu position="right">
        {props.pricing && <Menu.Item as={Link} to="/pricing">Pricing</Menu.Item>}
        <Menu.Item>
            <Dropdown pointing icon="user" {..._props}>
                {authContext.state === AuthState.auth ? <Dropdown.Menu>
                    <Dropdown.Header content={name}/>
                    <Dropdown.Item disabled className="menubar-email">{email}</Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item as={Link} to="/profile">Edit Profile</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu> : <div/>}
            </Dropdown>
        </Menu.Item>
    </Menu.Menu>
};

