import * as React from "react";
import {useEffect, useState, useContext} from "react";
import {RouteComponentProps} from "react-router";
import {Loader, Segment, Label, Button, Modal} from "semantic-ui-react";
import {GlyftyApi} from "../../services/GlyftyApi";
import {VoucherCreateEdit, VoucherCreateEditMode} from "../../components/VoucherCreateEdit";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {Voucher} from "../../common/Voucher";

export type VoucherPageParams = {
    voucherId: string;
}

export const VoucherEditPage = (props: RouteComponentProps<VoucherPageParams>) => {
    const [voucher, setVoucher] = useState();

    const authContext = useContext<AuthContextType>(AuthContext);
    const {voucherId} = props.match.params;

    useEffect(() => {
        GlyftyApi.getVoucher(voucherId).then(setVoucher);
    }, [voucherId]);

    if (!voucher) {
        return <Loader active={true}/>
    }

    function update(voucher: Voucher) {
        return GlyftyApi.updateVoucher(authContext, voucher).then(() => props.history.push("../../vouchers"));
    }

    function cancel() {
        props.history.push("../../vouchers");
    }

    function disable(e, data) {
        if (e.target.name === "confirm") {
            return GlyftyApi.disableVoucher(authContext, voucher).then(() => props.history.push("../../vouchers"));
        }
    }

    return <>
        <Segment>
            <Label attached="top">Edit Voucher</Label>
            <VoucherCreateEdit mode={VoucherCreateEditMode.EDIT} voucher={voucher} onSubmit={update} onCancel={cancel}/>
        </Segment>
        <Segment>
            <Label attached="top">Archive Voucher</Label>
            <p>When you archive a voucher it no longer appears in your voucher list and will not be available to purchase. Existing vouchers purchased will remain valid until
                used.</p>
            <p>Please ensure any links to the voucher purchase page are removed from publications, social media and so on before archiving the voucher.</p>
            <Modal
                trigger={<Button color="red">Archive</Button>}
                header="Archive Voucher"
                content='Are you sure?'
                actions={['Cancel', {key: 'confirm', content: 'OK', name: "confirm", positive: true}]}
                onActionClick={disable}
            />
        </Segment>
    </>
};