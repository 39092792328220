import * as React from "react";
import {UserOrganisation} from "../common/UserOrganisation";
import {Card, Button, Image, Header} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {ReactComponent as NewOrgIcon} from "../img/new_item.svg";
import {logoUrl} from "../services/util";

export const OrgList = (props: { orgs: UserOrganisation[] }) => {
    return <>
        {props.orgs.length && <Button as={Link} to="/connect" style={{float: "right"}} color="green">Add Organisation</Button>}
        <Header>Your organisations</Header>
        <p>You can add an organisation you want to manage or ask the owner of an existing organisation to add you.</p>
        <p></p>
        <Card.Group centered>
            {
                props.orgs
                    .map((o, index) => <Card key={index}>
                        <Card.Content textAlign="center">
                            <div><Image src={logoUrl(o.id)} className="org-logo" alt={o.name} centered as={Link} to={`/org/${o.id}/vouchers`}/></div>
                            <Card.Header><Link to={`/org/${o.id}/vouchers`}>{o.name}</Link></Card.Header>
                        </Card.Content>
                    </Card>)
            }
            {
                props.orgs.length === 0 &&
                <Card>
                    <Card.Content className="new-voucher">
                        <Link to="/connect"><NewOrgIcon/></Link>
                    </Card.Content>
                    <Button icon="plus square" as={Link} to="/connect" content="Create Organisation"/>
                </Card>
            }
        </Card.Group>
    </>
};