import {UserOrganisation} from "../common/UserOrganisation";
import {AuthContextType} from "../providers/AuthContextProvider";
import {Purchase} from "../common/Purchase";
import {Voucher} from "../common/Voucher";
import {absoluteUrl, g_fetch_auth, g_fetch_no_auth} from "./util";
import {ChargeStructure} from "../common/Organisation";
import {VoucherTemplate} from "../common/VoucherTemplate";

export const GlyftyApi = {
    getVouchers(authContext: AuthContextType, orgId: string): Promise<Voucher[]> {
        return g_fetch_auth(`organisation/${orgId}/vouchers`, authContext);
    },

    getTemplates(authContext: AuthContextType, orgId: string): Promise<VoucherTemplate[]> {
        return g_fetch_auth(`organisation/${orgId}/templates`, authContext);
    },

    getUsers(authContext: AuthContextType, orgId: string) {
        return g_fetch_auth(`organisation/${orgId}/users`, authContext, {method: "GET"});
    },

    getInvites(authContext: AuthContextType, orgId: string) {
        return g_fetch_auth(`organisation/${orgId}/invites`, authContext, {method: "GET"});
    },

    createPurchaseSession(voucherId: string, email: string, greeting: string) {
        return g_fetch_no_auth(`stripe/payment/session`, {
            method: "POST",
            body: JSON.stringify({
                voucherId,
                email,
                greeting,
                successUrl: absoluteUrl("/purchase_complete/{CHECKOUT_SESSION_ID}"),
                cancelUrl: absoluteUrl(`/purchase_cancelled/${voucherId}`)
            })
        });
    },

    getPurchaseForStripePurchaseId(stripePurchaseId: string): Promise<Purchase> {
        return g_fetch_no_auth(`purchase/${stripePurchaseId}/stripe`, {method: "GET"});
    },

    getPurchase(purchaseId: string) {
        return g_fetch_no_auth(`purchase/${purchaseId}`, {method: "GET"});
    },

    getVoucher(voucherId: string): Promise<Voucher> {
        return g_fetch_no_auth(`voucher/${voucherId}`, {method: "GET"});
    },

    createOrganisation(authContext: AuthContextType, name: string, code: string): Promise<UserOrganisation[]> {
        return g_fetch_auth("organisation", authContext, {
            method: "PUT",
            body: JSON.stringify({
                name: name,
                code
            })
        })
    },

    updateOrganisation(authContext: AuthContextType, orgId: string, {name, email}) {
        return g_fetch_auth("organisation", authContext, {
            method: "POST",
            body: JSON.stringify({
                orgId,
                name,
                email
            })
        })
    },

    addUserToOrg(authContext: AuthContextType, orgId: string, email: string, capabilities: string[]) {
        return g_fetch_auth(`organisation/${orgId}/add_user`, authContext, {
            method: "POST",
            body: JSON.stringify({
                email,
                capabilities
            })
        })
    },

    createUploadUrl(authContext: AuthContextType, organisationId: string): Promise<{ signedRequest: string, url: string }> {
        return g_fetch_auth(`s3sign/${organisationId}`, authContext);
    },

    redeem(authContext: AuthContextType, purchaseId: string) {
        return g_fetch_auth(`purchase/${purchaseId}/redeem`, authContext, {method: "POST"});
    },

    createVoucher(authContext: AuthContextType, voucher: Voucher) {
        return g_fetch_auth(`voucher`, authContext, {method: "PUT", body: JSON.stringify(voucher)});
    },

    createTemplate(authContext: AuthContextType, orgId: string, templateId: string, name: string) {
        return g_fetch_auth(`template`, authContext, {method: "PUT", body: JSON.stringify({id: templateId, orgId, name})});
    },

    updateTemplate(authContext: AuthContextType, updated: VoucherTemplate) {
        return g_fetch_auth(`template`, authContext, {method: "PUT", body: JSON.stringify(updated)});
    },

    createTemplateUploadUrl(authContext: AuthContextType, orgId: string) {
        return g_fetch_auth(`template/${orgId}/s3`, authContext);
    },

    updateVoucher(authContext: AuthContextType, voucher: Voucher) {
        console.log("UPDATE VOUCHER: ", voucher);
        return g_fetch_auth(`voucher`, authContext, {method: "POST", body: JSON.stringify(voucher)});
    },

    disableVoucher(authContext: AuthContextType, voucher: Voucher) {
        return g_fetch_auth(`voucher/${voucher.id}/enable/false`, authContext, {method: "POST"});
    },
    checkEmail(authContext: AuthContextType, email: string): Promise<string> {
        return g_fetch_auth(`email/check/${email}`, authContext, {method: "GET"});
    },
    deleteInvite(authContext: AuthContextType, orgId: string, inviteId: string) {
        return g_fetch_auth(`organisation/${orgId}/invite/${inviteId}`, authContext, {method: "DELETE"})
    },
    deleteTemplate(authContext: AuthContextType, orgId: string, templateId: string) {
        return g_fetch_auth(`organisation/${orgId}/template/${templateId}`, authContext, {method: "DELETE"})
    },
    getOrgUser(authContext: AuthContextType, orgId: string, userId: string) {
        return g_fetch_auth(`organisation/${orgId}/user/${userId}`, authContext, {method: "GET"});
    },
    updateUser(authContext: AuthContextType, orgId: string, userId: string, properties: { capabilities: {} }) {
        return g_fetch_auth(`organisation/${orgId}/user/${userId}`, authContext, {
            method: "POST",
            body: JSON.stringify(properties)
        });
    },
    deleteOrg(authContext: AuthContextType, orgId: string) {
        return g_fetch_auth(`organisation/${orgId}`, authContext, {method: "DELETE"});
    },
    removeOrgForCurrentUser(authContext: AuthContextType, orgId: string) {
        return g_fetch_auth(`organisation/${orgId}/user`, authContext, {method: "DELETE"});
    },
    removeUserFromOrg(authContext: AuthContextType, orgId: string, userId: string) {
        return g_fetch_auth(`organisation/${orgId}/user/${userId}`, authContext, {method: "DELETE"});
    },
    updateOrgBilling(authContext: AuthContextType, orgId: string, billing: ChargeStructure) {
        return g_fetch_auth(`organisation/${orgId}/billing`, authContext, {
            method: "POST",
            body: JSON.stringify(billing)
        });
    },
    enableOrg(authContext: AuthContextType, orgId: string, enabled: boolean) {
        return g_fetch_auth(`organisation/${orgId}/enable/${enabled}`, authContext, {method: "POST"});
    },
    getPurchases(authContext: AuthContextType, orgId: string, voucherId?: string, email?: string, startKey?: any) {
        return g_fetch_auth(`organisation/${orgId}/purchases`, authContext, {
            method: "POST",
            body: JSON.stringify({
                voucherId,
                email,
                startKey
            })
        });
    },
    getInfoForUsers(authContext: AuthContextType, userIds: string[]) {
        return g_fetch_auth(`userinfo`, authContext, {
            method: "POST",
            body: JSON.stringify(userIds)
        });
    },
    reissueVoucher(authContext: AuthContextType, purchaseId: string, email: string, greeting: string) {
        return g_fetch_auth(`purchase/${purchaseId}/reissue`, authContext, {
            method: "POST",
            body: JSON.stringify({email, greeting})
        });
    }
};