import * as React from "react";
import {Button, Container, Header, Icon, Message} from "semantic-ui-react";
import {AuthFormMode} from "../../components/AuthForm";
import {AuthRequired} from "../../components/AuthRequired";
import {AdminLayout} from "../../layouts/AdminLayout";
import {RouteComponentProps} from "react-router";
import {absoluteUrl, isLive, STRIPE_OAUTH_CLIENT_ID} from "../../services/util";

export const StripeConnectPage = (matchProps: RouteComponentProps<{}>) => {
    const redirectUrl = absoluteUrl("/stripe_connect");
    return <AdminLayout pricing {...matchProps}><AuthRequired mode={AuthFormMode.login}><Container className="main">
        <Header>Create Organisation</Header>
        <p>
            Glyfty uses Stripe (www.stripe.com) for payments. You will need to create a Stripe account if you don't already
            have one, and then connect Glyfty to your Stripe account, so that we can take payments using your account.
        </p>
        <p>
            Stripe gives you full control of your finances. Glyfty doesn't have direct access to your bank details or your customers card details.

            This also means you're responsible for processing refunds and any payment disputes for your users. <a href="https://stripe.com/docs/disputes">Learn more at
            stripe.com</a>.
        </p>
        <p>
            Click Connect to Stripe below and you'll be redirected to Stripe to connect accounts. You can create a Stripe account
            as part of this process, or connect to an existing account.
        </p>
        {
            isLive() || <Message warning header="Connecting Stripe Accounts in Test Mode">
                <p>You should not connect an activated (live) Stripe account in test mode. You should switch to the live Glyfty site at <a
                    href="https://www.glyfty.com">www.glyfty.com</a> before connecting to Stripe.</p>
                <p>Once your Stripe account is connected in live mode it will appear in test mode and you can make test purchases against it and perform other testing.</p>
                <p>If you continue and connect in test mode <b>you will not be able to make live mode purchases against this account</b>. You will need to delete and recreate the
                    Glyfty organisation, and re-connect it to your Stripe account.</p>
            </Message>
        }
        <p>
            <Button size="large" color="green" icon
                    href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_OAUTH_CLIENT_ID}&scope=read_write&redirect_uri=${redirectUrl}`}>Connect
                to Stripe <Icon
                    name="arrow right"/></Button>
        </p>
    </Container></AuthRequired></AdminLayout>
};