export enum Capability {
    SUPERUSER = "SUPERUSER",
    OWNER = "OWNER",
    VIEW_VOUCHERS = "VIEW_VOUCHERS",
    MODIFY_VOUCHERS = "MODIFY_VOUCHERS",
    VIEW_TEMPLATES = "VIEW_TEMPLATES",
    MODIFY_TEMPLATES = "MODIFY_TEMPLATES",
    VIEW_PURCHASES = "VIEW_PURCHASES",
    REDEEM_VOUCHER = "REDEEM_VOUCHER",
    VIEW_USERS = "VIEW_USERS",
    MODIFY_USERS = "MODIFY_USERS",
    MODIFY_ORGANISATION = "MODIFY_ORGANISATION",
}