import * as React from "react";
import {VoucherTemplateItem, VoucherTemplateItemType} from "../common/VoucherTemplateItem";
import {wrap} from "../common/common_utils";
import {templateUrl} from "../services/util";
import {Form, Icon} from "semantic-ui-react";
import {toDataURL} from "qrcode";
import Picker from 'vanilla-picker';

const DEFAULT_FONT_SIZE = 14;
const DEFAULT_QRCODE_SIZE = 120;

export const DEFAULT_TEMPLATE_PARAMS = [
    {type: VoucherTemplateItemType.QRCODE, x: 100, y: 50},
    {type: VoucherTemplateItemType.VOUCHER_ID, x: 100, y: 230},
    {type: VoucherTemplateItemType.ISSUE_DATE, x: 100, y: 250},
    {type: VoucherTemplateItemType.EXPIRY_DATE, x: 100, y: 270},
    {type: VoucherTemplateItemType.GREETING, x: 100, y: 300},
];

const GREETING = `Dear Sangeeta,

I really hope you enjoy this voucher. A friend of mine took this course and had a lot of fun!

Lots of love,
Sarah, Frank, Harry and Jane x`;

const TYPE_DESCRIPTIONS = {
    [VoucherTemplateItemType.QRCODE]: "QR Code",
    [VoucherTemplateItemType.VOUCHER_ID]: "Voucher Id",
    [VoucherTemplateItemType.ISSUE_DATE]: "Issue Date",
    [VoucherTemplateItemType.EXPIRY_DATE]: "Expiry Date",
    [VoucherTemplateItemType.GREETING]: "Greeting",
    [VoucherTemplateItemType.CUSTOM_TEXT]: "Custom Text",
};

type TemplateRenderProps = {
    orgId: string,
    templateId: string,
    items: VoucherTemplateItem[]
}

type TemplateParamEditorProps = {
    onChange(items: VoucherTemplateItem[]): void;
    params: VoucherTemplateItem[];
}

type ColourPickerProps = {
    colour?: string;
    name: string;
    onChange: (event: any, data: object) => void;
}

const ColorPicker = (props: ColourPickerProps) => {
    const [colour, setColour] = React.useState(props.colour || "black");

    const picker = new Picker();
    picker.setOptions({popup: "bottom"});
    picker.onDone = (colour) => {
        // this onChange sig to match other fields
        props.onChange(undefined, {name: props.name, value: colour.hex});
        setColour(colour.hex);
    };

    function attachPicker(el) {
        picker.setOptions({parent: el});
        picker.setColor(colour);
    }

    return <div className="field">
        <label>Colour</label>
        <div ref={attachPicker} className="ui input colour-picker"><input type="text" disabled width={100} style={{background: colour}}/></div>
    </div>
};

export const TemplateParamEditor = (props: TemplateParamEditorProps) => {
    const items = props.params;
    const [selectedItem, setSelectedItem] = React.useState(0);

    function handleChange(event, data) {
        switch (data.name) {
            case "value":
            case "colour":
                items[selectedItem][data.name] = data.value;
                break;
            default:
                items[selectedItem][data.name] = Number(data.value);
        }
        props.onChange(items);
    }

    function handleSelectedItemChange(event, data) {
        if (data.value === "new") {
            items.push({
                type: VoucherTemplateItemType.CUSTOM_TEXT,
                x: 0,
                y: DEFAULT_FONT_SIZE + 4,
                value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
            });
            props.onChange(items);
            setSelectedItem(items.length - 1);
        } else {
            if (items[selectedItem].type === VoucherTemplateItemType.CUSTOM_TEXT) {
                const value = items[selectedItem].value;
                if (!value || value.length === 0) {
                    items.splice(selectedItem, 1);
                    props.onChange(items);
                    if (data.value > selectedItem) {
                        data.value--;
                    }
                }
            }
            setSelectedItem(data.value);
        }
    }

    const options = items.map((i, index) => ({
        key: index,
        value: index,
        text: TYPE_DESCRIPTIONS[i.type]
    } as any));

    options.push({
        key: "new",
        value: "new",
        text: "Add Custom Text"
    });

    const xyControls = <>
        <Form.Input label="X Position" type="number" step="5" name="x" width="2" value={items[selectedItem].x} onChange={handleChange}/>
        <Form.Input label="Y Position" type="number" step="5" name="y" width="2" value={items[selectedItem].y} onChange={handleChange}/>
    </>;

    const fontControls = <>
        {/* hack to ensure component is recreated when props change -- https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key */}
        <ColorPicker key={JSON.stringify(items[selectedItem])} name="colour" colour={items[selectedItem].colour} onChange={handleChange}/>
        <Form.Input label="Size" type="number" name="size" width="2" value={items[selectedItem].size || DEFAULT_FONT_SIZE} onChange={handleChange}/>
    </>;

    return <>
        <Form.Group width="equal">
            <Form.Select label="Item" width="3" options={options} value={selectedItem} onChange={handleSelectedItemChange}/>
            {{
                [VoucherTemplateItemType.QRCODE]: <>
                    {xyControls}
                    <Form.Input label="Size" type="number" min="120" name="size" width="2" value={items[selectedItem].size || DEFAULT_QRCODE_SIZE} onChange={handleChange}/>
                </>,
                [VoucherTemplateItemType.VOUCHER_ID]: <>{xyControls}{fontControls}</>,
                [VoucherTemplateItemType.ISSUE_DATE]: <>{xyControls}{fontControls}</>,
                [VoucherTemplateItemType.EXPIRY_DATE]: <>{xyControls}{fontControls}</>,
                [VoucherTemplateItemType.CUSTOM_TEXT]: <>{xyControls}{fontControls}</>,
                [VoucherTemplateItemType.GREETING]: <>
                    {xyControls}
                    {fontControls}
                    <Form.Input label="Line Length" type="number" name="lineLength" value={items[selectedItem].lineLength || 40} onChange={handleChange}/>
                </>
            }[items[selectedItem].type]}
        </Form.Group>
        <Form.Group>
            {{
                [VoucherTemplateItemType.CUSTOM_TEXT]: <>
                    <Form.Input icon={<Icon name='close' link onClick={() => handleChange(null, {name: "value", value: ""})}/>} label="Text" width="12" name="value"
                                value={items[selectedItem].value || ""} onChange={handleChange}/>
                </>
            }[items[selectedItem].type]}
        </Form.Group>
    </>
};

export const TemplateRender = (props: TemplateRenderProps) => {
    const [image, setImage] = React.useState();
    const {orgId, templateId} = props;

    function setCanvasRef(canvas: HTMLCanvasElement) {
        if (!canvas) {
            return;
        }
        const context = canvas.getContext("2d")!;
        context.drawImage(image, 0, 0);
        for (const item of props.items) {
            const fontSize = item.size || DEFAULT_FONT_SIZE;
            switch (item.type) {
                case VoucherTemplateItemType.QRCODE:
                    toDataURL("this is some sample text in the qrcode", {margin: 1, width: item.size || DEFAULT_QRCODE_SIZE},
                        (err, url) => {
                            if (!err) {
                                const qrcodeObj = new Image();
                                qrcodeObj.onload = function () {
                                    context.drawImage(qrcodeObj, item.x, item.y);
                                };
                                qrcodeObj.src = url;
                            }
                        });
                    break;
                case VoucherTemplateItemType.VOUCHER_ID:
                    context.fillStyle = item.colour || "#000000";
                    context.font = `${fontSize}px Arial`;
                    context.fillText("v_AnF5q653rkheGZhtw5d", item.x, item.y);
                    break;
                case VoucherTemplateItemType.ISSUE_DATE:
                    context.fillStyle = item.colour || "#000000";
                    context.font = `${fontSize}px Arial`;
                    context.fillText(item.value || new Date().toLocaleDateString(), item.x, item.y);
                    break;
                case VoucherTemplateItemType.EXPIRY_DATE:
                    context.fillStyle = item.colour || "#000000";
                    context.font = `${fontSize}px Arial`;
                    context.fillText(item.value || "Never", item.x, item.y);
                    break;
                case VoucherTemplateItemType.GREETING:
                    context.fillStyle = item.colour || "#000000";
                    context.font = `${fontSize}px Arial`;
                    const lines = wrap((item.value || GREETING).split("\n"), item.lineLength || 40);
                    for (let n = 0; n < lines.length; n++) {
                        context.fillText(lines[n], item.x, item.y + (Number(fontSize) + 2) * n);
                    }
                    break;
                case VoucherTemplateItemType.CUSTOM_TEXT:
                    context.fillStyle = item.colour || "#000000";
                    context.font = `${fontSize}px Arial`;
                    context.fillText(item.value || "", item.x, item.y);
                    break;
            }
        }
    }

    React.useEffect(() => {
        const background = new Image();
        background.onload = function () {
            setImage(background);
        };
        background.src = templateUrl(orgId, templateId);
    }, [orgId, templateId]);

    return <div>
        {image &&
        <canvas ref={setCanvasRef} width={image.naturalWidth} height={image.naturalHeight} style={{background: "none"}}/>}
    </div>
};

