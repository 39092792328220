import * as React from "react";
import {useContext, useEffect} from "react";
import {Redirect, RouteComponentProps} from "react-router";
import queryString from "querystring";
import {Button, Container, Form, Header, InputOnChangeData, Message, Segment} from "semantic-ui-react";
import {GlyftyApi} from "../../services/GlyftyApi";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {AuthRequired} from "../../components/AuthRequired";
import {AuthFormMode} from "../../components/AuthForm";
import {useAsyncCallback} from "react-async-hook";
import useForm from "react-hook-form";
import {describeError} from "../../services/util";

type StripeErrorParams = {
    error: string,
    error_description: string
}

type StripeConnectParams = {
    code: string
}

function isSuccess(qs: StripeConnectParams | any): qs is StripeConnectParams {
    return (qs as StripeConnectParams).code !== undefined
}

export const OrgCreatePage = (props: RouteComponentProps<StripeErrorParams | any>) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const params = queryString.parse(props.location.search.substr(1));

    const {register, getValues, errors, handleSubmit, setValue} = useForm();
    const asyncSubmit = useAsyncCallback(handleSubmit(createOrg));

    useEffect(() => {
        register({name: "orgName"}, {required: "This field is required"});
    }, [register]);

    if (!isSuccess(params)) {
        return <Container className="main"><Segment raised color="teal">
            <Header>Oops, that didn't work!</Header>
            <p>A problem occurred connecting your stripe account. Stripe says:</p>
            <Message>
                {params.error_description}
            </Message>
        </Segment></Container>
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    async function createOrg() {
        const orgs = await GlyftyApi.createOrganisation(authContext, getValues().orgName, params.code as string);
        authContext.actions.setOrganisations(orgs);
    }

    if (asyncSubmit.status === "success") {
        return <Redirect to="/" push={true}/>;
    }

    console.log("ASYNC: ", asyncSubmit);

    return <AuthRequired mode={AuthFormMode.login}>
        <Container className="main"><Segment raised color="teal">
            <Form onSubmit={asyncSubmit.execute} error={asyncSubmit.error !== undefined}>
                <Header>Stripe connection success, you're nearly done!</Header>
                <Form.Input label="Enter a name for your new organisation (you can change this later)" name="orgName" placeholder="Organisation name" onChange={handleChange}
                            error={describeError(errors.email)}/>

                {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}

                <Button loading={asyncSubmit.loading} color="green">Create</Button>
            </Form>
        </Segment>
        </Container>
    </AuthRequired>

};
