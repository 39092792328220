export enum ChargeType {
    NONE = "NONE",
    PERCENTAGE_PLUS_FEE = "PERCENTAGE_PLUS_FEE",
    PERCENTAGE_OR_FEE = "PERCENTAGE_OR_FEE"
}

export type ChargeStructure = {
    type: ChargeType,
    minFee?: number,
    percentage?: number
}

export type Organisation = {
    id: string;
    name: string;
    stripeAccountId: string,
    enabled: boolean;
    email: string,
    billing: ChargeStructure
}