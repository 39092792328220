import * as React from "react";
import {useContext, useState} from "react";
import {RouteComponentProps} from "react-router";
import {GlyftyApi} from "../../services/GlyftyApi";
import {Button, Card, Label, Loader, Dropdown, Accordion, Icon, Header} from "semantic-ui-react";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {ReactComponent as NewVoucherIcon} from "../../img/new_item.svg";
import {Link} from "react-router-dom";
import {OrgPageParams} from "../../components/OrgPageRouter";
import {CapabilityGuard} from "../../components/CapabilityGuard";
import {Capability} from "../../common/Capability";
import CopyToClipboard from "react-copy-to-clipboard";
import {useAsync} from "react-async-hook";
import {Voucher} from "../../common/Voucher";
import {absoluteUrl} from "../../services/util";

type VoucherDisplayProps = {
    voucher: Voucher,
    orgId: string
}

const VoucherDisplay = (props: VoucherDisplayProps) => {
    const {voucher: v, orgId} = props;
    const [copied, setCopied] = useState();

    function copyDone() {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    }

    const purchaseUrl = (v) => absoluteUrl("/purchase/" + v.id);

    return <Card>
        <Card.Content>
            <Dropdown icon="ellipsis vertical" className="voucher hamburger">
                <Dropdown.Menu>
                    <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_VOUCHERS]}>
                        <Dropdown.Item icon="edit" content="Edit" as={Link} to={`/org/${orgId}/voucher/edit/${v.id}`}/>
                    </CapabilityGuard>
                    <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_VOUCHERS]}>
                        <Dropdown.Item icon="images outline" content="Configure Template" as={Link} to={`/org/${orgId}/voucher/template/${v.id}`}/>
                    </CapabilityGuard>
                    <CapabilityGuard orgId={orgId} capabilities={[Capability.VIEW_PURCHASES]}>
                        <Dropdown.Item icon="list" content="Purchases" as={Link} to={`/org/${orgId}/voucher/purchases/${v.id}`}/>
                    </CapabilityGuard>
                    <Dropdown.Item icon="external" content="Purchase Page" as={Link} to={"/purchase/" + v.id}/>
                    <CopyToClipboard text={purchaseUrl(v)} onCopy={() => copyDone()}><Dropdown.Item icon="copy outline" content="Copy Purchase Link"/></CopyToClipboard>
                </Dropdown.Menu>
            </Dropdown>

            {/*
            <Label icon="external" floating color="teal" title="Purchase Page"/>
*/}
            <Card.Header><Link to={`/org/${orgId}/voucher/edit/${v.id}`}>{v.title}</Link></Card.Header>
            <Card.Description>{v.description_short}</Card.Description>
            <div className="voucher-labels">
                <div>
                    <Label icon="exchange" content={v.count} title="Usage Limit"/>
                    <Label icon="pound" content={(v.cost / 100).toFixed(2)} title="Price"/>
                    {copied && <Label className="copy-purchase-link" content="Copied!" color="green"/>}
                </div>
            </div>
        </Card.Content>
    </Card>
};

export const VoucherListPage = ({match}: RouteComponentProps<OrgPageParams>) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const [showArchived, setShowArchived] = React.useState();

    const orgId = match.params.orgId;

    const asyncFetch = useAsync(async () => {
        return GlyftyApi.getVouchers(authContext, orgId);
    }, [orgId]);

    if (asyncFetch.loading) {
        return <Loader active={true}/>
    }

    const empty = asyncFetch.result!.length === 0;
    const archived = asyncFetch.result!.filter(v => v.disabled === true);

    return <>
        <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_VOUCHERS]}>
            {empty || <p><Button as={Link} to={`/org/${orgId}/voucher/create`} style={{float: "right"}} color="green">Add Voucher</Button></p>}
        </CapabilityGuard>

        {empty && <p>There aren't any active vouchers for this organisation</p>}

        <Card.Group centered style={{clear: "both"}}>
            {
                asyncFetch.result!
                    .filter(v => v.disabled !== true)
                    .map((v, index) => <VoucherDisplay key={index} voucher={v} orgId={orgId}/>)
            }
            {empty && <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_VOUCHERS]}>
                <Card>
                    <Card.Content className="new-voucher">
                        <Link to={`/org/${orgId}/voucher/create`}><NewVoucherIcon className="new-voc"/></Link>
                    </Card.Content>
                    <Button basic icon="plus square" as={Link} to={`/org/${orgId}/voucher/create`} content="Create New Voucher"/>
                </Card>
            </CapabilityGuard>}
        </Card.Group>
        {
            archived.length ? <Accordion>
                <Accordion.Title
                    as={Header}
                    active={showArchived}
                    style={{textAlign: "center", marginTop: "24px", color: "grey"}}
                    onClick={() => setShowArchived(!showArchived)}
                >
                    <Icon name='dropdown'/>
                    Archived Vouchers
                </Accordion.Title>
                <Accordion.Content active={showArchived}>
                    <Card.Group centered style={{clear: "both"}}>
                        {
                            archived.map((v, index) => <VoucherDisplay key={index} voucher={v} orgId={orgId}/>)
                        }
                    </Card.Group>
                </Accordion.Content>
            </Accordion> : <></>
        }

    </>
};