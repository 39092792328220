import * as React from "react";
import {createContext, useState} from "react";

import * as AWS from 'aws-sdk/global';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"
import {CognitoUser, CognitoUserAttribute, CognitoUserSession, CookieStorage} from "amazon-cognito-identity-js"
import {UserOrganisation} from "../common/UserOrganisation";
import {g_fetch_idtoken, isTestOrLive} from "../services/util";
import {useAsync} from "react-async-hook";
import {Message} from "semantic-ui-react";

AWS.config.region = 'eu-west-1';

const Storage = isTestOrLive() ? new CookieStorage({domain: "glyfty.com", secure: true}) : undefined;

const poolData = {
    UserPoolId: 'eu-west-1_fNFfTbfBm',
    ClientId: '5tc06mnugqlouq4o9q4qf39kvr',
    Storage
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export enum AuthState {
    loading,
    unauth,
    auth,
}

type AuthContextActions = {
    login: (email: string, password: string) => Promise<void>;
    register: (name: string, email: string, password: string) => Promise<void>;
    logout: () => void;
    setName(name: string): Promise<void>
    setOrganisations(organisations: UserOrganisation[]): void;
    verify(email: string, verificationCode: string): Promise<void>;
    resendCode(email: string): Promise<void>;
    requestResetPassword(email: string): Promise<void>;
    completeResetPassword(email: string, password: string, code: string): Promise<void>;
    currentSession(): Promise<CognitoUserSession>;
}

export type AuthContextType = {
    actions: AuthContextActions,
    state: AuthState,
    attributes: CognitoUserAttribute[],
    organisations: UserOrganisation[],
    // selectedOrganisation: string
};

export const AuthContext = createContext<AuthContextType>({} as any);

type AuthContextProviderProps = {
    orgId?: string
}

export async function getCognitoSession(cognitoUser: CognitoUser): Promise<CognitoUserSession> {
    return new Promise<CognitoUserSession>((resolve, reject) => cognitoUser.getSession((err, session) => err ? reject(err) : resolve(session)))
}

function cognitoUserData(email: string) {
    const userData = {
        Username: email,
        Pool: userPool,
        Storage
    };
    return userData;
}

export const AuthContextProvider: React.FC = (props) => {
    const [state, setState] = useState(AuthState.loading);
    const [refreshToken, setRefreshToken] = useState();
    const [attributes, setAttributes] = useState<CognitoUserAttribute[]>([]);
    const [organisations, setOrganisations] = useState<UserOrganisation[]>([]);

    const asyncLoad = useAsync(async () => {
        try {
            const session = await currentSession(false);
            if (session && session.isValid()) {
                return loadUser(session);
            } else {
                setState(AuthState.unauth);
            }
        } catch (e) {
            setState(AuthState.unauth);
            throw e;
        }
    }, []);

    function getUserAttributes(cognitoUser: CognitoUser) {
        return getCognitoSession(cognitoUser).then(() => {
            return new Promise<CognitoUserAttribute[]>((resolve, reject) => cognitoUser.getUserAttributes((err, attributes) => err ? reject(err) : resolve(attributes)))
        })
    }

    async function loadUser(session: CognitoUserSession) {
        const cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
            // not sure this can happen
            throw new Error("No current user!");
        }
        const idToken = session.getIdToken().getJwtToken();
        const attributes = await getUserAttributes(cognitoUser);
        const organisations = await g_fetch_idtoken("organisation/list", idToken);
        setOrganisations(organisations);
        setAttributes(attributes);
        setState(AuthState.auth);
    }

    function logout() {
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
            // noinspection JSIgnoredPromiseFromCall
            cognitoUser.signOut();
            setState(AuthState.unauth);
        }
    }

    function register(name: string, email: string, password: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const nameAttr = new CognitoUserAttribute({Name: "name", Value: name});
            userPool.signUp(email, password, [nameAttr], [], err => err ? reject(err) : resolve());
        }).then(() => login(email, password))
    }

    function verify(email: string, code: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const userData = cognitoUserData(email);
            const cognitoUser = new CognitoUser(userData);
            cognitoUser.confirmRegistration(code, true, err => err ? reject(err) : resolve());
        })
    }

    function requestResetPassword(email: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const userData = cognitoUserData(email);
            const cognitoUser = new CognitoUser(userData);
            cognitoUser.forgotPassword({onSuccess: resolve, onFailure: reject/*, inputVerificationCode TODO ???*/});
        })
    }

    function completeResetPassword(email: string, password: string, code: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const userData = cognitoUserData(email);
            console.log("Confirm password reset: ", userData);
            const cognitoUser = new CognitoUser(userData);
            cognitoUser.confirmPassword(code, password, {onSuccess: resolve, onFailure: reject});
        })
    }

    function resendCode(email: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const userData = cognitoUserData(email);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
            console.log("Requesting new verification code for: ", email);
            cognitoUser.resendConfirmationCode(err => err ? reject(err) : resolve());
        })
    }

    async function setName(name: string) {
        const cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
            throw new Error("User is not logged in");
        }

        await requireSession();

        return new Promise<void>((resolve, reject) => {
            cognitoUser.updateAttributes([{Name: "name", Value: name}], err => {
                if (err) {
                    console.log("ERROR FROM UPDATE: ", err);
                    return reject(err);
                }
                console.log("Name updated: ", err);
                resolve();
            })
        });
    }

    function login(email: string, password: string): Promise<void> {
        const authenticationData = {
            Username: email,
            Password: password,
        };
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        const userData = cognitoUserData(email);
        const cognitoUser = new CognitoUser(userData);

        return new Promise<void>((resolve, reject) => {
            // noinspection JSUnusedGlobalSymbols
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async function (/*result*/) {
                    const session = await currentSession(false);
                    if (!session) {
                        // TODO: not sure if/when this can happen?!?
                        reject(new Error("Problem logging in"));
                        return;
                    }
                    return loadUser(session).then(() => resolve(), reject);
                },

                newPasswordRequired: function (userAttributes/*, requiredAttributes*/) {
                    // User was signed up by an admin and must provide new
                    // password and required attributes, if any, to complete
                    // authentication.

                    // the api doesn't accept this field back
                    delete userAttributes.email_verified;

                    reject();
                    // store userAttributes on global variable
                    // cognitoUser.completeNewPasswordChallenge("Cascade9!", userAttributes, {
                    //     onSuccess: () => {
                    //         console.log("Success on password challenge!");
                    //     },
                    //     onFailure: (e) => {
                    //         console.log("Error!", e);
                    //     }
                    // });
                },

                onFailure: function (err) {
                    console.log("Error in login: ", err);
                    reject(err);
                },
            });
        })
    }

    async function requireSession(): Promise<CognitoUserSession> {
        return await currentSession(true) as CognitoUserSession;
    }

    async function currentSession(strict: boolean): Promise<CognitoUserSession | void> {
        const cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
            setState(AuthState.unauth);
            if (strict) {
                throw new Error("Not logged in");
            } else {
                console.log("User is not logged in");
            }
            return;
        }

        const session = await getCognitoSession(cognitoUser);
        if (!session.isValid()) {
            console.log("Refreshing Cognito session");
            return new Promise((resolve, reject) => {
                cognitoUser.refreshSession(refreshToken, (err, newSession: CognitoUserSession) => {
                    if (err) {
                        setState(AuthState.unauth);
                        reject(err);
                    } else {
                        console.log("Successfully refreshed session");
                        setRefreshToken(newSession.getRefreshToken());
                        return newSession;
                    }
                })
            })
        }
        return session;
    }

    const context = {
        state,
        // selectedOrganisation,
        attributes,
        organisations,
        actions: {
            login,
            logout,
            register,
            verify,
            requestResetPassword,
            completeResetPassword,
            setName,
            resendCode,
            currentSession: requireSession,
            setOrganisations
        }
    };

    return <AuthContext.Provider value={context}>
        {asyncLoad.error && <Message error content={asyncLoad.error.message}/>}
        {props.children}
    </AuthContext.Provider>
};