import * as React from "react";
import {Menu, Responsive} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {CapabilityGuard} from "./CapabilityGuard";
import {Capability} from "../common/Capability";
import {Organisation} from "../common/Organisation";
import {ContentWithoutDiv} from "./MenuBarOrganisations";

type OrgMenuProps = {
    org: Organisation,
    active: string,
}

export const OrgMenu = (props: OrgMenuProps) => {
    const orgId = props.org.id;

    return <Responsive as={ContentWithoutDiv} minWidth={520}>
        <Menu tabular>
            <Menu.Item
                name='vouchers'
                active={props.active === "vouchers"}
                as={Link}
                to={"/org/" + orgId + "/vouchers"}
            />
            <Menu.Item
                name='purchases'
                active={props.active === "purchases"}
                as={Link}
                to={"/org/" + orgId + "/purchases"}
            />
            <Menu.Item
                name='Templates'
                active={props.active === "templates"}
                as={Link}
                to={"/org/" + orgId + "/templates"}
            />
            <Menu.Item
                name='users'
                active={props.active === "users"}
                as={Link}
                to={"/org/" + orgId + "/users"}
            />
            <Menu.Item
                name='settings'
                active={props.active === "settings"}
                as={Link}
                to={"/org/" + orgId + "/settings"}
            />
            <CapabilityGuard orgId={orgId} capabilities={[Capability.SUPERUSER]}>
                <Menu.Item
                    name='admin'
                    active={props.active === "admin"}
                    as={Link}
                    to={"/org/" + orgId + "/admin"}
                />
{/*
                <Menu.Item>
                    <Checkbox label="Enabled" defaultChecked={props.org.enabled} toggle onChange={handleChange}/>
                    {asyncSubmit.error && <span className="inline-error">{asyncSubmit.error.message}</span>}
                </Menu.Item>
*/}
            </CapabilityGuard>
        </Menu>
    </Responsive>
};