import * as React from "react";
import {OrgPageParams} from "../../components/OrgPageRouter";
import {RouteComponentProps} from "react-router";
import {GlyftyApi} from "../../services/GlyftyApi";
import {PublicLayout} from "../../layouts/PublicLayout";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {Button, Form, InputOnChangeData, Loader, Message, TextArea} from "semantic-ui-react";
import useForm from "react-hook-form";
import {describeError} from "../../services/util";
import {Purchase} from "../../common/Purchase";
import {TemplateRender} from "../../components/TemplateConfiguration";
import {VoucherTemplateItemType} from "../../common/VoucherTemplateItem";
import {Voucher} from "../../common/Voucher";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {useContext} from "react";

type ReissueFormProps = {
    voucher: Voucher,
    purchase: Purchase,
    onCancel(): void,
    onSubmit(email: string, greeting: string): void
}

const ReissueForm = ({voucher, purchase, onCancel, onSubmit}: ReissueFormProps) => {
    const {register, getValues, errors, handleSubmit, setValue, watch} = useForm({
        defaultValues: {
            email: purchase.email,
            greeting: purchase.greeting
        }
    });

    const asyncSubmit = useAsyncCallback(handleSubmit(reissueVoucher));

    React.useEffect(() => {
        register({name: "email"}, {required: "This field is required"});
        register({name: "greeting"});
    }, [register]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    function reissueVoucher() {
        const {email, greeting} = getValues();
        console.log("REISSUE: ", email, greeting);
        return onSubmit(email, greeting);
    }

    const greeting = watch("greeting");

    const templateItems = voucher.templateItems!.map(i => {
        switch (i.type) {
            case VoucherTemplateItemType.ISSUE_DATE:
                return {...i, value: new Date(purchase.created).toLocaleDateString("en-gb")};
            case VoucherTemplateItemType.EXPIRY_DATE:
                return {...i, value: new Date(purchase.validUntil).toLocaleDateString("en-gb")};
            case VoucherTemplateItemType.GREETING:
                return {
                    ...i,
                    value: greeting
                };
        }
        return i;
    });

    return <>
        <Form onSubmit={asyncSubmit.execute} error={asyncSubmit.error !== undefined}>
            <Form.Input label="Enter customer email" name="email" placeholder="Enter customer email for reissued voucher" defaultValue={purchase.email} onChange={handleChange}
                        error={describeError(errors.email)}/>

            {
                voucher.templateId && <div>
                    <Form.Field control={TextArea} required defaultValue={purchase.greeting} name="greeting" label="Message to recipient"
                                rows={8} className="purchase-greeting"
                                placeholder='Your message'
                                onChange={handleChange}
                                error={describeError(errors.greeting)}/>
                    <TemplateRender orgId={purchase.orgId} templateId={voucher.templateId} items={templateItems}/>
                </div>
            }

            {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}

            <p>
                <Button loading={asyncSubmit.loading} type="submit" icon="send" content="Reissue"/>
                <Button type="cancel" onClick={onCancel}>Cancel</Button>
            </p>
        </Form>
    </>

};


export const ReissueVoucherPage = ({match, history}: RouteComponentProps<OrgPageParams & { purchaseId: string }>) => {
    const authContext = useContext<AuthContextType>(AuthContext);

    const voucherAsync = useAsync(
        async () => {
            const purchase = await GlyftyApi.getPurchase(match.params.purchaseId) as Purchase;
            const voucher = await GlyftyApi.getVoucher(purchase.voucherId);

            return {purchase, voucher};
        },
        [match.params.purchaseId]
    );

    if (voucherAsync.loading) {
        return <Loader content={"Loading"}/>
    }

    if (voucherAsync.error) {
        return <PublicLayout>
            <Message error content={voucherAsync.error.message}/>
        </PublicLayout>
    }

    const {voucher, purchase} = voucherAsync.result!;

    async function reissueVoucher(email: string, greeting: string) {
        await GlyftyApi.reissueVoucher(authContext, purchase.id, email, greeting);
        history.goBack();
    }

    function cancel() {
        history.goBack();
    }

    return <ReissueForm voucher={voucher} purchase={purchase} onCancel={cancel} onSubmit={reissueVoucher}/>
};
