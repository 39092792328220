import * as React from "react";
import {useContext, FunctionComponent} from "react";
import {AuthContext, AuthState} from "../providers/AuthContextProvider";
import {AuthForm, AuthFormMode} from "./AuthForm";
import {MenuBarSecondary} from "./MenuBarOrganisations";
import {Loader, Container} from "semantic-ui-react";

type AuthRequiredProps = {
    mode: AuthFormMode
};

export const AuthRequired: FunctionComponent<AuthRequiredProps> = (props) => {
    const authContext = useContext(AuthContext);

    return {
        [AuthState.loading]: <MenuBarSecondary><Loader active inline size="tiny"/>&nbsp;&nbsp;&nbsp;Loading</MenuBarSecondary>,
        [AuthState.unauth]: <Container className="main"><AuthForm mode={props.mode}/></Container>,
        [AuthState.auth]: props.children
    }[authContext.state] as any
};

