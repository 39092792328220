import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, Checkbox, Form, Header, InputOnChangeData, Label, Message, Segment} from "semantic-ui-react";
import ButtonLink from "./util/ButtonLink";
import {AuthContext} from "../providers/AuthContextProvider";
import {Redirect} from "react-router";
import useForm from 'react-hook-form'
import {FieldError} from "react-hook-form/dist/types";
import {useAsyncCallback} from 'react-async-hook';
import {Link} from "react-router-dom";

const {Field} = Form;

export enum AuthFormMode {
    register,
    login,
    verifyRegistration,
    requestResetPassword,
    completeResetPassword,
    redirect
}

function describeError(error?: FieldError) {
    if (error) {
        return error.message;
    }
}

type AuthFormCommonProps = {
    onSwitchMode(mode: AuthFormMode, email?: string, password?: string): void;
    email?: string,
    password?: string,
}

const AuthFormLogin = (props: AuthFormCommonProps) => {
    const authContext = useContext(AuthContext);
    const {register, getValues, errors, handleSubmit, setValue} = useForm({defaultValues: {email: props.email}});
    const asyncSubmit = useAsyncCallback(handleSubmit(submitLogin));

    async function submitLogin() {
        const form = getValues();
        return authContext.actions
            .login(form.email, form.password)
            .then(() => props.onSwitchMode(AuthFormMode.redirect))
            .catch(e => {
                switch (e.code) {
                    case "UserNotConfirmedException":
                        props.onSwitchMode(AuthFormMode.verifyRegistration, form.email, form.password);
                        break;
                    case "UserNotFoundException":
                    case "NotAuthorizedException":
                        throw new Error("Invalid email address or password");
                    default:
                        throw e;
                }
            });
    }

    useEffect(() => {
        register({name: "email"}, {required: "This field is required"});
        register({name: "password"}, {required: "This field is required"});
    }, [register]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    function showRegistration() {
        props.onSwitchMode(AuthFormMode.register, getValues().email);
    }

    function showResetPassword() {
        const {email} = getValues();
        props.onSwitchMode(AuthFormMode.requestResetPassword, email);
    }

    return <Form error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
        <Header>Log In</Header>
        <input name="hidden" type="text" style={{display: "none"}}/>

        <Form.Input label="Email" name="email" placeholder='Email' defaultValue={props.email} onChange={handleChange} error={describeError(errors.email)}/>
        <Form.Input label="Password" name="password" type="password" placeholder='Password' onChange={handleChange} error={describeError(errors.password)}/>

        {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}

        <Button type="submit" loading={asyncSubmit.loading}>Log In</Button>
        <p>Not registered? <ButtonLink onClick={showRegistration}>Register</ButtonLink></p>
        <p><ButtonLink onClick={showResetPassword}>Forgotten password</ButtonLink></p>
    </Form>
};

const AuthFormRegister = (props: AuthFormCommonProps) => {
    const authContext = useContext(AuthContext);

    const {register, getValues, errors, handleSubmit, setValue} = useForm({defaultValues: {email: props.email}});
    const [confirmPasswordFormError, setConfirmPasswordFormError] = useState();
    const asyncSubmit = useAsyncCallback(handleSubmit(submitRegistration));

    useEffect(() => {
        register({name: "name"}, {required: "This field is required"});
        register({name: "email"}, {required: "This field is required"});
        register({name: "registerPassword"}, {required: "This field is required"});
        register({name: "confirmPassword"}, {required: "This field is required"});
        register({name: "tandc"}, {required: "You must accept the terms and conditions"});
    }, [register]);

    async function submitRegistration() {
        const form = getValues();
        if (form.registerPassword !== form.confirmPassword) {
            setConfirmPasswordFormError("Passwords do not match");
            return;
        }

        setConfirmPasswordFormError(undefined);

        return authContext.actions
            .register(form.name, form.email, form.registerPassword)
            .catch(e => {
                // noinspection JSRedundantSwitchStatement
                switch (e.code) {
                    case "UserNotConfirmedException":
                        props.onSwitchMode(AuthFormMode.verifyRegistration, form.email, form.registerPassword);
                        break;
                    default:
                        throw e;
                }
            });
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    function handleTcChange(event, data) {
        const {name, checked} = data;
        setValue(name, checked ? true : undefined);
    }

    return <Form autoComplete="off" error={(asyncSubmit.error || errors) !== undefined} onSubmit={asyncSubmit.execute}>
        <Header>New Account</Header>
        <input name="hidden" type="text" style={{display: "none"}}/>
        <Form.Input label="Name" name="name" placeholder='Your full name' onChange={handleChange} error={describeError(errors.name)}/>
        <Form.Input label="Email" autoComplete="off" name="email" defaultValue={props.email} placeholder='Email' onChange={handleChange} error={describeError(errors.email)}/>
        <Form.Input label="Password" autoComplete="new-password" name="registerPassword" type="password" placeholder='Password'
                    onChange={handleChange} error={describeError(errors.registerPassword)}/>

        <Form.Input label="Confirm Password" autoComplete="off" name="confirmPassword" type="password" placeholder='Confirm Password'
                    onChange={handleChange} error={describeError(errors.confirmPassword) || confirmPasswordFormError}/>

        <Field>
            <Checkbox label={<label>I agree to the <Link to="/tc">Terms and Conditions</Link></label>} name="tandc" onChange={handleTcChange}/>
            {errors.tandc && <div><Label basic color="red" pointing prompt>{errors.tandc.message}</Label></div>}
        </Field>

        {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}

        <Button type="submit" loading={asyncSubmit.loading}>Register</Button>
        <p>Already registered? <ButtonLink onClick={() => props.onSwitchMode(AuthFormMode.login)}>Sign in</ButtonLink></p>
    </Form>
};

const AuthFormVerify = (props: AuthFormCommonProps) => {
    const authContext = useContext(AuthContext);
    const {register, getValues, errors, handleSubmit, setValue} = useForm();
    const asyncSubmit = useAsyncCallback(handleSubmit(submitCode));
    const asyncResend = useAsyncCallback(resendCode);

    async function submitCode() {
        const form = getValues();
        asyncResend.reset();
        await authContext.actions.verify(props.email!, form.code);
        await authContext.actions.login(props.email!, props.password!);
        props.onSwitchMode(AuthFormMode.redirect);
    }

    useEffect(() => {
        register({name: "code"}, {required: "This field is required"});
    }, [register]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value.trim());
    }

    function resendCode() {
        return authContext.actions.resendCode(props.email!);
    }

    function cancel(e) {
        e.preventDefault();
        props.onSwitchMode(AuthFormMode.login);
    }

    return <Form autoComplete="off" error={asyncSubmit.error !== undefined} success={asyncResend.status === "success"} onSubmit={asyncSubmit.execute}>
        <Header>Verify Email</Header>
        <input name="hidden" type="text" style={{display: "none"}}/>
        <p>Please enter the verification code you received by email to complete registration.</p>
        <p>If you didn't receive the email, <ButtonLink onClick={asyncResend.execute}>request another verification code</ButtonLink>.</p>

{/*
        <Form.Input label="Email" type="text" name="email" defaultValue={props.email} disabled/>
*/}

        <Form.Input label="Verification Code" type="text" name="code" placeholder='Code'
                    onChange={handleChange} error={describeError(errors.verificationCode)}/>

        {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}
        {asyncResend.status === "success" && <Message success content="Code resent, please check your email"/>}

        <Button type="submit" loading={asyncSubmit.loading}>Verify</Button>
        <Button onClick={cancel}>Cancel</Button>
    </Form>
};

const AuthFormRequestResetPassword = (props: AuthFormCommonProps) => {
    const authContext = useContext(AuthContext);
    const {register, getValues, errors, handleSubmit, setValue} = useForm({defaultValues: {email: props.email}});
    const asyncSubmit = useAsyncCallback(handleSubmit(submitForgottenPassword));

    async function submitForgottenPassword() {
        const form = getValues();
        return authContext.actions.requestResetPassword(form.email)
            .then(() => props.onSwitchMode(AuthFormMode.completeResetPassword, form.email))
    }

    useEffect(() => {
        register({name: "email"}, {required: "This field is required"});
    }, [register]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    function cancel(e) {
        e.preventDefault();
        props.onSwitchMode(AuthFormMode.login);
    }

    return <Form error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
        <Header>Reset Password</Header>
        <p>Please enter your email to reset your password.</p>

        <Form.Input label="Email" type="text" name="email" placeholder='Email to reset' defaultValue={props.email}
                    onChange={handleChange} error={describeError(errors.email)}/>

        {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}

        <Button type="submit" loading={asyncSubmit.loading}>Reset</Button>
        <Button onClick={cancel}>Cancel</Button>

    </Form>
};

const AuthFormCompleteResetPassword = (props: AuthFormCommonProps) => {
    const authContext = useContext(AuthContext);
    const {register, getValues, errors, handleSubmit, setValue} = useForm({defaultValues: {email: props.email}});
    const [confirmPasswordFormError, setConfirmPasswordFormError] = useState();
    const asyncSubmit = useAsyncCallback(handleSubmit(completResetPassword));

    async function completResetPassword() {
        const form = getValues();
        if (form.password !== form.confirmPassword) {
            setConfirmPasswordFormError("Passwords do not match");
            return;
        }

        setConfirmPasswordFormError(undefined);

        await authContext.actions.completeResetPassword(props.email!, form.password, form.code);
        await authContext.actions.login(props.email!, form.password);
        props.onSwitchMode(AuthFormMode.redirect);
    }

    useEffect(() => {
        register({name: "code"}, {required: "This field is required"});
        register({name: "password"}, {required: "This field is required"});
        register({name: "confirmPassword"}, {required: "This field is required"});
    }, [register]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    function cancel(e) {
        e.preventDefault();
        props.onSwitchMode(AuthFormMode.login);
    }

    return <Form error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
        <Header>Set New Password</Header>
        <p>Please enter the verification code you received by email and your new password.</p>

        <Form.Input label="Email" type="text" name="email" disabled defaultValue={props.email}/>

        <Form.Input label="Code" type="text" name="code" placeholder='Verification code'
                    onChange={handleChange} error={describeError(errors.code)}/>

        <Form.Input label="Password" autoComplete="new-password" name="password" type="password" placeholder='New password'
                    onChange={handleChange} error={describeError(errors.registerPassword)}/>

        <Form.Input label="Confirm Password" autoComplete="off" name="confirmPassword" type="password" placeholder='Confirm password'
                    onChange={handleChange} error={describeError(errors.confirmPassword) || confirmPasswordFormError}/>

        {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}

        <Button type="submit" loading={asyncSubmit.loading}>Reset</Button>
        <Button onClick={cancel}>Cancel</Button>

    </Form>
};

export const AuthForm = (props: { mode: AuthFormMode }) => {
    const [mode, setMode] = useState(props.mode);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    function switchMode(mode: AuthFormMode, email?: string, password?: string) {
        setMode(mode);
        if (email) {
            setEmail(email);
        }
        if (password) {
            setPassword(password);
        }
    }

    const component = {
        [AuthFormMode.register]:
            <AuthFormRegister onSwitchMode={switchMode} email={email}/>,

        [AuthFormMode.login]:
            <AuthFormLogin onSwitchMode={switchMode} email={email}/>,

        [AuthFormMode.verifyRegistration]:
            <AuthFormVerify onSwitchMode={switchMode} email={email} password={password}/>,

        [AuthFormMode.requestResetPassword]:
            <AuthFormRequestResetPassword onSwitchMode={switchMode} email={email}/>,

        [AuthFormMode.completeResetPassword]:
            <AuthFormCompleteResetPassword onSwitchMode={switchMode} email={email}/>,

        [AuthFormMode.redirect]:
            <Redirect to="/"/>,
    }[mode];

    return <Segment color="teal">{component}</Segment>
};