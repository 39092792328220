import * as React from "react";
import {RouteComponentProps} from "react-router";
import {GlyftyApi} from "../../services/GlyftyApi";
import {Container, Header, Image, Loader, Message} from "semantic-ui-react";
import {toCanvas} from "qrcode";
import {PublicLayout} from "../../layouts/PublicLayout";
import {logoUrl} from "../../services/util";
import {useAsync} from "react-async-hook";
import {PoweredBy} from "../../components/PoweredBy";
import {Purchase} from "../../common/Purchase";

type VoucherDisplayParams = {
    purchaseId: string
}

export const VoucherDisplayPage = ({match}: RouteComponentProps<VoucherDisplayParams>) => {
    // const [canvas, setCanvas] = useState<HTMLCanvasElement>();

    const voucherAsync = useAsync(
        () => GlyftyApi.getPurchase(match.params.purchaseId)
            .then(purchase => {
                return GlyftyApi.getVoucher(purchase.voucherId)
                    .then(voucher => ({
                        purchase,
                        voucher
                    }))
            })
            .then(info => {
                const {hostname, port: portRaw} = window.location;
                const port = portRaw.length ? ":" + portRaw : "";
                const url = new URL(`//${hostname}${port}/redeem/${info.purchase.id}`, document.baseURI).href;
                return {
                    ...info,
                    url
                }
            }),
        [match.params.purchaseId]
    );

    if (voucherAsync.loading) {
        return <Loader content={"Loading"}/>
    }

    if (voucherAsync.error) {
        return <PublicLayout>
            <Message error>An error occurred: {voucherAsync.error.message}</Message>
        </PublicLayout>
    }

    const voucher = voucherAsync.result!.voucher;
    const purchase = voucherAsync.result!.purchase as Purchase;

    function setCanvasOnMount(canvas: HTMLCanvasElement) {
        toCanvas(canvas, voucherAsync.result!.url, {margin: 1, errorCorrectionLevel: "high", width: 230}, error => {
            if (error) {
                // setError(error);
                return;
            }
            // setCanvas(canvas);
        })
    }

    function expiry() {
        if ( purchase.validUntil ) {
            return "Voucher expires on "+new Date(purchase.validUntil).toLocaleDateString("en-gb");
        }
        return "Voucher does not expire"
    }

    return <PublicLayout>
        <div className="voucher" style={{textAlign: "center"}}>
            <Image src={logoUrl(voucher.orgId)} inline verticalAlign={"top"}/>
            <canvas ref={setCanvasOnMount} width={300} height={300}/>

            <div><Header as='h2' className="voucher-title">
                <Header.Content>
                    {voucher.title}
                </Header.Content>
            </Header></div>
            <Container>
                <p className="voucher-description">
                    {voucher.description_recipient}
                </p>
                <p>{expiry()}</p>
            </Container>

            <PoweredBy/>
        </div>

    </PublicLayout>
};

