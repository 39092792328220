import * as React from "react";
import {useContext, useEffect} from "react";
import {AuthContext} from "../../providers/AuthContextProvider";
import {Container, Form, Header, Input, InputOnChangeData, Label, Message, Segment, SegmentGroup} from "semantic-ui-react";
import useForm from "react-hook-form";
import {useAsyncCallback} from "react-async-hook";
import {findAttribute, findSub} from "../../services/util";
import {FieldError} from "react-hook-form/dist/types";
import {UserOrganisation} from "../../common/UserOrganisation";
import {GlyftyApi} from "../../services/GlyftyApi";
import {GlyftyConfirm} from "../../components/util/GlyftyConfirm";

function describeError(error?: FieldError) {
    if (error) {
        return error.message;
    }
}

const OrgUserSegment = ({org}: { org: UserOrganisation }) => {
    const authContext = useContext(AuthContext);
    const asyncSubmit = useAsyncCallback(removeOrg);

    async function removeOrg() {
        const orgs = await GlyftyApi.removeOrgForCurrentUser(authContext, org.id);
        authContext.actions.setOrganisations(orgs);
    }

    return <Segment>
        <Header>{org.name}</Header>
        <div className="userorg-details">
            <GlyftyConfirm floated="right" color="red" loading={asyncSubmit.loading} prompt="Remove" header={"Remove " + org.name}
                           content="Are you sure? You will no longer have access to this organisation unless someone adds you again."
                           onOpen={asyncSubmit.reset} onConfirm={asyncSubmit.execute}/>
            {
                org.capabilities.map(c => <Label key={c}>{c}</Label>)
            }
            {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}
        </div>
    </Segment>
};

export const ProfileEditPage = () => {
    const authContext = useContext(AuthContext);
    const name = findAttribute(authContext.attributes, "name", false);
    const id = findSub(authContext.attributes);

    const defaultValues = {name};
    const {register, getValues, errors, handleSubmit, setValue, reset: resetForm} = useForm({defaultValues});
    const asyncSubmit = useAsyncCallback(handleSubmit(updateUser));

    useEffect(() => {
        register({name: "name"}, {required: "This field is required"});
    }, [register]);

    function updateUser() {
        console.log("Name to set: ", getValues());
        return authContext.actions.setName(getValues().name);
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    function reset() {
        setValue("name", name);
        resetForm();
    }

    return <Container className="main">
        <Segment>
            <Label attached="top">Edit Basic Profile</Label>
            <Form success={asyncSubmit.status === "success"} error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
                <Form.Field control={Input} required name="name" label={<><label>Name<span className="user-id">{id}</span></label>
                </>} defaultValue={name}
                            placeholder='Your name' onChange={handleChange} error={describeError(errors.email)}/>

                <Message success content="Updated successfully"/>
                {asyncSubmit.error && <Message error>{asyncSubmit.error.message}</Message>}

                <Form.Group>
                    <Form.Button loading={asyncSubmit.loading} color="green">Update</Form.Button>
                    <Form.Button disabled={asyncSubmit.loading} type="reset" onClick={reset}>Reset</Form.Button>
                </Form.Group>
            </Form>
        </Segment>
        <SegmentGroup>
            <Label attached="top">Organisation Membership</Label>
            {
                authContext.organisations.map(org => <OrgUserSegment key={org.id} org={org}/>)
            }
            {
                authContext.organisations.length === 0 && <p>
                    You are not linked to any organisations. You can create a new organisation and link it to a Stripe account or
                    you can ask someone in an existing organisation to add you by email.
                </p>
            }
        </SegmentGroup>
    </Container>
};