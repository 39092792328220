import React from 'react';

import './App.css';

import 'semantic-ui-css/semantic.min.css'
import {Route} from 'react-router';
import {HomePage} from "./pages/HomePage";
import {BrowserRouter, Link} from 'react-router-dom';
import {StripeConnectPage} from "./pages/admin/StripeConnectPage";
import {OrgCreatePage} from "./pages/admin/OrgCreatePage";
import {VoucherPurchasePage} from "./pages/public/VoucherPurchasePage";
import {AdminLayout} from "./layouts/AdminLayout";
import {VoucherPurchaseSuccessPage} from "./pages/public/VoucherPurchaseSuccessPage";
import {RedeemPage} from "./pages/admin/RedeemPage";
import {AuthRequired} from "./components/AuthRequired";
import {AuthForm, AuthFormMode} from "./components/AuthForm";
import {OrgPageRouter} from "./components/OrgPageRouter";
import {VoucherDisplayPage} from "./pages/public/VoucherDisplayPage";
import {ProfileEditPage} from "./pages/admin/ProfileEditPage";
import {SiteBanner} from "./components/SiteBanner";
import {PrivacyPage, TcPage} from "./pages/admin/Legal";
import {Container, Header, Segment} from 'semantic-ui-react';
import {PricingPage} from "./pages/PricingPage";

const _App: React.FC = () => {
    return (
        <>
            <SiteBanner/>
            <BrowserRouter>
                <Route path="/" exact={true} component={HomePage}/>

                <Route path="/connect" component={StripeConnectPage}/>

                <Route path="/stripe_connect" render={matchProps =>
                    <AdminLayout {...matchProps}>
                        <OrgCreatePage {...matchProps}/>
                    </AdminLayout>
                }/>

                <Route path="/purchase/:voucherId" component={VoucherPurchasePage}/>

                <Route path="/profile" render={matchProps =>
                    <AdminLayout pricing {...matchProps}>
                        <AuthRequired mode={AuthFormMode.login}>
                            <ProfileEditPage/>
                        </AuthRequired>
                    </AdminLayout>}
                />

                <Route path="/purchase_complete/:stripePurchaseId" component={VoucherPurchaseSuccessPage}/>
                <Route path="/voucher/:purchaseId" component={VoucherDisplayPage}/>

                <Route path="/redeem/:purchaseId" render={matchProps =>
                    <AdminLayout {...matchProps}>
                        <RedeemPage {...matchProps}/>
                    </AdminLayout>}
                />

                <Route path="/org/:orgId" render={(matchProps) =>
                    <AdminLayout pricing {...matchProps}>
                        <AuthRequired mode={AuthFormMode.login}>
                            <OrgPageRouter {...matchProps}/>
                        </AuthRequired>
                    </AdminLayout>
                }/>

                <Route path="/signin" render={matchProps =>
                    <AdminLayout pricing {...matchProps}>
                        <Container className="main">
                            <AuthForm mode={AuthFormMode.login}/>
                        </Container>
                    </AdminLayout>
                }/>

                <Route path="/register" render={matchProps =>
                    <AdminLayout pricing {...matchProps}>
                        <Container className="main">
                            <AuthForm mode={AuthFormMode.register}/>
                        </Container>
                    </AdminLayout>
                }/>

                <Route path="/pricing" render={matchProps =>
                    <AdminLayout {...matchProps}>
                        <Segment className="pricing-header" vertical inverted textAlign="center">
                            <Container>
                                <Header>
                                    Simple design means lower costs
                                </Header>
                            </Container>
                        </Segment>
                        <Container className="main">
                            <PricingPage/>
                        </Container>
                    </AdminLayout>
                }/>

                <Route path="/tc" render={TcPage}/>
                <Route path="/privacy" render={PrivacyPage}/>

                <div className="footer">
                    <a href="mailto:info@glyfty.com">Contact</a>|
                    <Link to="/privacy">Privacy</Link>|
                    <Link to="/tc">Terms and Conditions</Link>
                </div>

            </BrowserRouter>
        </>
    );
};

export default _App;
