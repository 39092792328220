import * as React from "react";
import {Modal, Button, IconProps, SemanticShorthandItem, SemanticFLOATS, SemanticCOLORS} from "semantic-ui-react";

type GlyftyConfirmProps = {
    loading?: boolean;
    color?: SemanticCOLORS;
    floated?: SemanticFLOATS;
    basic?: boolean;
    icon?: boolean | SemanticShorthandItem<IconProps>
    prompt: string;
    header: string;
    content: string;
    onConfirm(): void;
    onOpen?(): void;
}

export const GlyftyConfirm = ({prompt, onConfirm, basic, icon, loading, floated, color, ...modalProps}: GlyftyConfirmProps) => {
    function click(e, d) {
        if (e.target.name === "ok") {
            onConfirm();
        }
    }

    return <Modal trigger={<Button type="button" color={color} floated={floated} loading={loading} basic={basic} icon={icon} content={prompt}/>} {...modalProps}
                  onActionClick={click} actions={["Cancel", {key: "ok", name: "ok", content: "OK", positive: true}]}
    />;
};

