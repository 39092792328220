import * as React from "react"
import {AuthContextProvider} from "../providers/AuthContextProvider";
import {MenuBarRight} from "../components/MenuBarRight";
import {Menu} from "semantic-ui-react";
import {Link, RouteComponentProps} from "react-router-dom";
import {FC} from "react";
import {ReactComponent as GlyftyLogo} from "../img/glyfty-logo-with-text-export.svg";

type AdminLayoutProps = {
    pricing?: boolean
}

export const AdminLayout: FC<RouteComponentProps & AdminLayoutProps> = (props) => {
    return <AuthContextProvider>
        <div className="App">
            <Menu borderless attached="top">
                <Menu.Item header className="site-header" as={Link} to="/"><GlyftyLogo className="logo"/></Menu.Item>
                <MenuBarRight pricing={props.pricing} {...props}/>
            </Menu>
            {props.children}

        </div>
    </AuthContextProvider>

};