import * as React from "react";
import {GlyftyApi} from "../../services/GlyftyApi";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {VoucherPageParams} from "./VoucherEditPage";
import {RouteComponentProps} from "react-router";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {Button, Form, Loader, Message} from "semantic-ui-react";
import {GlyftyConfirm} from "../../components/util/GlyftyConfirm";
import {Link} from "react-router-dom";
import {VoucherTemplateItem} from "../../common/VoucherTemplateItem";
import {DEFAULT_TEMPLATE_PARAMS, TemplateParamEditor, TemplateRender} from "../../components/TemplateConfiguration";

function clone(items: VoucherTemplateItem[]): VoucherTemplateItem[] {
    if (!items) {
        return [];
    }
    return items.map(i => ({...i}))
}

export const VoucherTemplatePage = (props: RouteComponentProps<VoucherPageParams>) => {
    const {voucherId} = props.match.params;
    const authContext = React.useContext<AuthContextType>(AuthContext);
    const [templateId, setTemplateId] = React.useState();
    const [paramsChanged, setParamsChanged] = React.useState();
    const [params, setParams] = React.useState<VoucherTemplateItem[]>(DEFAULT_TEMPLATE_PARAMS);
    const asyncSubmit = useAsyncCallback(updateVoucher);
    const asyncDelete = useAsyncCallback(deleteTemplateFromVoucher);

    const asyncFetch = useAsync(async () => {
        const voucher = await GlyftyApi.getVoucher(voucherId);
        const templates = await GlyftyApi.getTemplates(authContext, voucher.orgId);
        setTemplateId(voucher.templateId);
        if (voucher.templateItems) {
            setParams(clone(voucher.templateItems));
            setParamsChanged(true);
        }
        return {voucher, templates}
    }, [voucherId]);

    async function updateVoucher() {
        const updated = {
            ...voucher,
            templateId,
            templateItems: params
        };
        await GlyftyApi.updateVoucher(authContext, updated);
        props.history.push("../../vouchers");
    }

    async function deleteTemplateFromVoucher() {
        delete voucher.templateId;
        delete voucher.templateItems;
        await GlyftyApi.updateVoucher(authContext, voucher);
        props.history.push("../../vouchers");
    }

    if (asyncFetch.loading) {
        return <Loader active={true}/>
    }

    if (asyncFetch.error) {
        return <Message error content={asyncFetch.error.message}/>;
    }

    function onTemplateSelected(event, data) {
        const {value} = data;
        if (!templateId || !paramsChanged) {
            const template = templates.find(t => t.id === value)!;
            if (template.templateItems) {
                setParams(clone(template.templateItems));
            }
        }
        setTemplateId(value);
    }

    function onParamsChanged(params) {
        setParams(clone(params));
        setParamsChanged(true);
    }

    function resetDefaults() {
        const template = templates.find(t => t.id === templateId)!;
        if (template.templateItems) {
            setParams(clone(template.templateItems));
            setParamsChanged(false);
        }
    }

    function cancel() {
        props.history.goBack();
    }

    const {voucher, templates} = asyncFetch.result!;

    if (!templates.length) {
        return <>
            <p>You have not uploaded any printable voucher templates yet.</p>
            <p><Button as={Link} to={`/org/${voucher.orgId}/templates`} content="Manage Templates"/></p>
        </>
    }

    const options = templates.map(t => ({key: t.id, value: t.id, text: t.name}));
    return <Form loading={asyncSubmit.loading || asyncDelete.loading} onSubmit={asyncSubmit.execute} error={!!(asyncSubmit.error || asyncDelete.error)}>
        {voucher.templateId &&
        <GlyftyConfirm loading={asyncDelete.loading} icon="trash alternate outline" floated="right" basic prompt="Use Simple Email" header="Use Simple Email"
                       content="Are you sure? This will cause this voucher to use the simple email style (no printable attachment). Any custom configuration will be lost"
                       onConfirm={asyncDelete.execute}/>}

        {templateId && paramsChanged && <GlyftyConfirm loading={false} prompt="Reset Defaults" basic icon="undo" floated="right" header={"Reset Template Defaults"}
                                                       content="Are you sure? This will reset voucher layout to template defaults. Any custom configuration will be lost"
                                                       onConfirm={resetDefaults}/>
        }
        <Form.Group>
            <Form.Select label="Select Template" name="templateId" defaultValue={voucher.templateId} options={options} onChange={onTemplateSelected}/>
        </Form.Group>
        {
            templateId ? <>
                <TemplateParamEditor onChange={onParamsChanged} params={params}/>
                <TemplateRender orgId={voucher.orgId} templateId={templateId} items={params}/>

                <p>
                    <Button type="submit" color="green" loading={asyncSubmit.loading} content="Save Changes"/>
                    <Button type="button" onClick={cancel} content="Cancel"/>
                </p>
            </> : <p>Select a template from the list above to start configuring your printable voucher.</p>
        }
    </Form>
};