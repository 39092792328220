import React, {useContext} from "react";
import {AuthContext, AuthState} from "../providers/AuthContextProvider";
import {Breadcrumb, Container, Dropdown, Responsive} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {CapabilityGuard} from "./CapabilityGuard";
import {Capability} from "../common/Capability";

export const MenuBarSecondary = (props) => {
    return <div className="menubar-lined">
        <Container>
            {props.children}
        </Container>
    </div>
};

export const ContentWithoutDiv: React.FC = ({children}) => <>{children}</>;

export const MenuBarOrganisations = (props: { history: any, orgId?: string }) => {
    const authContext = useContext(AuthContext);

    const currentOrg = authContext.organisations.find(o => o.id === props.orgId);

    const options = authContext
        .organisations
        .map(o => ({
            key: o.id,
            text: o.name,
            value: o.id
        }));

    // function changeOrganisation(e, v) {
    //     console.log("Change to: ", v.value);
    //     props.history.push("/org/" + v.value + "/vouchers");
    // }

    return <MenuBarSecondary>
        {
            authContext.state === AuthState.unauth
                ? <div>You are not signed in</div>
                : authContext.state === AuthState.loading || options.length > 0 ?
                <>
                    <Breadcrumb>
                        <Breadcrumb.Section content="Home" link={true} onClick={() => props.history.push("/")}/>
                        {
                            currentOrg &&
                            <>
                                <Breadcrumb.Divider icon="angle right"/>
                                <Breadcrumb.Section content={currentOrg.name} link={true} onClick={() => props.history.push(`/org/${props.orgId}/vouchers`)}/>
                            </>
                        }
                    </Breadcrumb>
                    {currentOrg &&
                    <Responsive as={ContentWithoutDiv} maxWidth={520}>
                        <Dropdown icon="bars" pointing="top right" className="org hamburger">
                            <Dropdown.Menu>
                                <Dropdown.Item name="viewDetails" icon="window maximize outline" content="Vouchers"
                                               as={Link} to={"/org/" + currentOrg.id + "/vouchers"}/>
                                <Dropdown.Item icon="list" content="Purchases" as={Link}
                                               to={"/org/" + currentOrg.id + "/purchases"}/>
                                <Dropdown.Item icon="images outline" content="Templates" as={Link}
                                               to={"/org/" + currentOrg.id + "/templates"}/>
                                <Dropdown.Item icon="user" content="Users" as={Link}
                                               to={"/org/" + currentOrg.id + "/users"}/>
                                <Dropdown.Item icon="cog" content="Settings" as={Link}
                                               to={"/org/" + currentOrg.id + "/settings"}/>
                                <CapabilityGuard orgId={currentOrg.id} capabilities={[Capability.SUPERUSER]}>
                                    <Dropdown.Item icon="fire" content="Admin" as={Link}
                                                   to={"/org/" + currentOrg.id + "/admin"}
                                    />
                                </CapabilityGuard>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Responsive>
                    }

                    {/*
                    Home | &nbsp;
                    <Dropdown text={props.orgId ? undefined : "Please select an organisation"} inline options={options} value={props.orgId}
                              onLabelClick={changeOrganisation} onChange={changeOrganisation}/>
*/}
                </> :
                <>You do not have any organisations yet</>
        }
    </MenuBarSecondary>
};