import * as React from "react";
import {useContext} from "react";
import {AuthContext, AuthContextType} from "../providers/AuthContextProvider";
import {Capability} from "../common/Capability";

type CapabilityGuardProps = {
    orgId: string;
    capabilities: string[];
    alt?: string;
}

function userHasCapability(userCapabilities: string[], allowedCapabilities: string[]) {
    if (allowedCapabilities.length === 1 && allowedCapabilities[0] === Capability.SUPERUSER) {
        // superuser only
        return userCapabilities.some(c => c === Capability.SUPERUSER);
    }
    return allowedCapabilities.length === 0
        || allowedCapabilities
            .concat(Capability.OWNER, Capability.SUPERUSER)
            .some(t => userCapabilities.some(c => t === c))
}

export const CapabilityGuard: React.FC<CapabilityGuardProps> = ({orgId, capabilities, children, alt}) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const org = authContext.organisations.find(o => o.id === orgId)!;
    return userHasCapability(org.capabilities, capabilities) ? <>{children}</> : <div>{alt}</div>
};