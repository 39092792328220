import * as React from "react";
import {RouteComponentProps} from "react-router";
import {GlyftyApi} from "../../services/GlyftyApi";
import {Header, Image, Loader, Message, Form, Button} from "semantic-ui-react";
import {PublicLayout} from "../../layouts/PublicLayout";
import {isLive, logoUrl, printableVoucherUrl} from "../../services/util";
import {useAsync} from "react-async-hook";
import {PoweredBy} from "../../components/PoweredBy";
import {EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton} from "react-share";
import {Link} from "react-router-dom";

type VoucherDisplayParams = {
    stripePurchaseId: string
}

const IS_MOBILE = 'ontouchstart' in document.documentElement;

export const VoucherPurchaseSuccessPage = ({match}: RouteComponentProps<VoucherDisplayParams>) => {
    const [printableReady, setPrintableReady] = React.useState();
    const [printableError, setPrintableError] = React.useState();

    const voucherAsync = useAsync(
        () => GlyftyApi.getPurchaseForStripePurchaseId(match.params.stripePurchaseId)
            .then(purchase => {
                return GlyftyApi.getVoucher(purchase.voucherId)
                    .then(voucher => ({
                        purchase,
                        voucher
                    }))
            })
            .then(info => {
                const {hostname, port: portRaw} = window.location;
                const port = portRaw.length ? ":" + portRaw : "";
                // in live (only) we direct traffic to mini-html page which will set the page title and then redirect in meta
                const url = isLive() ? new URL(`//voucher.glyfty.com/l/${info.purchase.id}`, document.baseURI).href :
                    new URL(`//${hostname}${port}/voucher/${info.purchase.id}`, document.baseURI).href;
                return {
                    ...info,
                    url
                }
            }),
        [match.params.stripePurchaseId]
    );

    if (voucherAsync.loading) {
        return <Loader content={"Loading"}/>
    }

    if (voucherAsync.error) {
        return <PublicLayout>
            <Message error>An error occurred: {voucherAsync.error.message}</Message>
        </PublicLayout>
    }

    const voucher = voucherAsync.result!.voucher;
    const purchase = voucherAsync.result!.purchase;
    const printableUrl = printableVoucherUrl(purchase.id);

    async function awaitPrintableVoucher() {
        let tries = 30;
        while (tries--) {
            const result = await fetch(printableUrl);
            if (result.status === 200) {
                return true; // found it
            }
            // 404 or some other error, pause and retry
            await new Promise((resolve) => setTimeout(resolve, 2000));
        }
        throw new Error("Error generating printable voucher. Please check your email")
    }

    if (voucher.templateId && !printableReady && !printableError) {
        awaitPrintableVoucher()
            .then(setPrintableReady)
            .catch(setPrintableError);
    }

    function downloadPrintable(e) {
        const link = document.createElement('a');
        link.href = printableUrl;
        link.click();
        e.preventDefault();
        e.stopPropagation();
    }

    return <PublicLayout>
        <div className="voucher" style={{textAlign: "center"}}>
            <Image inline className="org-logo" src={logoUrl(voucher.orgId)}/>

            <div><Header as='h2' className="voucher-title">
                <Header.Content>
                    {voucher.title}
                </Header.Content>
            </Header></div>
            <p className="purchase-success-message">
                PURCHASE SUCCESS
            </p>
            <p>
                The voucher has been sent to you by email along with a purchase receipt. Thank you.
            </p>

            {voucher.templateId ? <div>
                {
                    printableReady ? <div>
                        <p><Image as="a" href={printableUrl} centered src={printableUrl} style={{width: "100%", maxWidth: "400px"}}/></p>
                        <p><Button as="a" href={printableUrl}>Download Printable Voucher</Button></p>
                    </div> : printableError ? <Message error>{printableError.message}</Message> :
                        <Loader inline="centered" active={true} content="Preparing printable voucher. Please wait a moment..."/>
                }
            </div> : <>
                <Header as='h5' icon textAlign='center'>
                    <Header.Content>Share This Voucher</Header.Content>
                </Header>

                {IS_MOBILE && <>
                    <WhatsappShareButton url={voucherAsync.result!.url} title={`Your voucher!`}>
                        <WhatsappIcon size={64} round/>
                    </WhatsappShareButton>
                    <FacebookShareButton url={voucherAsync.result!.url}>
                        <FacebookIcon size={64} round/>
                    </FacebookShareButton>
                </>}

                <EmailShareButton url={voucherAsync.result!.url} subject="Your voucher!" body="">
                    <EmailIcon size={64} round/>
                </EmailShareButton>

                {IS_MOBILE ? <p>You can share the voucher using the sharing buttons above.</p> :
                    <p>You can share the voucher by email using the button above.</p>}

                <p>
                    You can also view the voucher as the recipient will see it, for example <br/> if you want to screen grab it and send as an image or share directly.
                </p>
                <p><a href={voucherAsync.result!.url}>View Voucher</a></p>
            </>}


            <PoweredBy/>
        </div>

    </PublicLayout>
};

