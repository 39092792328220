import * as React from "react";
import {useEffect} from "react";
import {Form, Input, Message, TextArea} from "semantic-ui-react";
import {describeError} from "../services/util";
import useForm from "react-hook-form";
import {useAsyncCallback} from "react-async-hook";
import {Voucher} from "../common/Voucher";

export enum VoucherCreateEditMode {
    CREATE,
    EDIT
}

type VoucherCreateEditProps = {
    mode: VoucherCreateEditMode
    voucher: Voucher
    onSubmit(voucher: Voucher): Promise<void>
    onCancel(): void
};

const DURATION_OPTIONS = [
    {key: 0, value: 0, text: "Never"},
    {key: 30, value: 30, text: "30 days"},
    {key: 90, value: 90, text: "90 days"},
    {key: 365, value: 365, text: "1 year"},
    {key: 365 * 2, value: 365 * 2, text: "2 years"},
    {key: 365 * 3, value: 365 * 3, text: "3 years"}
];

export const VoucherCreateEdit = (props: VoucherCreateEditProps) => {
    const {register, getValues, errors, handleSubmit, setValue, watch} = useForm({defaultValues: props.voucher});
    const asyncSubmit = useAsyncCallback(handleSubmit(updateFields));

    useEffect(() => {
        register({name: "title"}, {required: true});
        register({name: "description_short"}, {required: true});
        register({name: "description_purchase"}, {required: true});
        register({name: "description_recipient"}, {required: true});
        register({name: "count", type: "number"}, {required: true});
        register({name: "cost", type: "number"}, {required: true, pattern: /[0-9]+/});
        register({name: "duration", type: "number"}, {required: true});
        register({name: "orgId"}, {required: true});
        register({name: "id"});
    }, [register]);

    function updateFields() {
        const {count, cost, duration, ...string_props} = getValues();
        if (Math.floor(cost) !== Number(cost)) {
            throw new Error("Cost must be a whole number of pence");
        }
        return props.onSubmit({
            ...props.voucher,
            ...string_props,
            count: Number.parseInt(count),
            cost: Number.parseInt(cost),
            duration: Number.parseInt(duration) || 0
        } as Voucher);
    }

    function handleChange(event, data) {
        const {name, value} = data;
        console.log("Set value for name=", name, "value=", value);
        setValue(name, value);
    }

    const formState = getValues();
    const cost = watch("cost");

    return <Form success={asyncSubmit.status === "success"} error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
        <Form.Field control={Input} required name="title" label="Title" placeholder='Title of the voucher' defaultValue={formState.title} onChange={handleChange}
                    error={describeError(errors.name)}/>

        <Form.Field control={Input} required name="description_short" label="Description (internal)"
                    placeholder='Short description of the voucher (not shown to users)'
                    defaultValue={formState.description_short}
                    onChange={handleChange}
                    error={describeError(errors.description_short)}/>

        <Form.Field control={TextArea} required name="description_purchase" label="Description (purchase page)"
                    placeholder='How the voucher is described (on the purchase page)'
                    defaultValue={formState.description_purchase}
                    onChange={handleChange}
                    error={describeError(errors.description_purchase)}/>

        <Form.Field control={TextArea} required name="description_recipient" label="Description (recipient)"
                    placeholder='How the voucher is described (to the recipient)'
                    defaultValue={formState.description_recipient}
                    onChange={handleChange}
                    error={describeError(errors.description_recipient)}/>

        <Form.Group>
            <Form.Field control={Input} required name="count" label="Usage Limit" placeholder='How many times voucher can be used' defaultValue={formState.count}
                        onChange={handleChange}
                        error={describeError(errors.count)}/>
            <Form.Field control={Input} required name="cost" label="Cost (pence)" placeholder='Cost in pence' defaultValue={formState.cost} onChange={handleChange}
                        error={describeError(errors.cost)}/>
            <Form.Select label="Expires" name="duration" options={DURATION_OPTIONS} defaultValue={formState.duration} onChange={handleChange}/>
        </Form.Group>

        <p>Voucher will cost {cost && "£" + (formState.cost / 100).toFixed(2)}</p>

        {asyncSubmit.error && <Message error>{asyncSubmit.error.message}</Message>}

        <Form.Group>
            <Form.Button loading={asyncSubmit.loading} type="submit" color="green">{props.mode === VoucherCreateEditMode.CREATE ? "Create" : "Save"}</Form.Button>
            <Form.Button disabled={asyncSubmit.loading} type="cancel" onClick={props.onCancel}>Cancel</Form.Button>
        </Form.Group>
    </Form>

};