import * as React from "react";
import {useEffect} from "react";
import {RouteComponentProps} from "react-router";
import {GlyftyApi} from "../../services/GlyftyApi";
import {Button, Checkbox, Container, Form, Header, Image, InputOnChangeData, Label, Loader, Message, Statistic, TextArea} from "semantic-ui-react";
import {PublicLayout} from "../../layouts/PublicLayout";
import {EMAIL_REGEX, expiryDate, logoUrl, STRIPE_PUBLIC_KEY} from "../../services/util";
import {useAsync, useAsyncCallback} from "react-async-hook";
import useForm from "react-hook-form";
import {FieldError} from "react-hook-form/dist/types";
import {PoweredBy} from "../../components/PoweredBy";
import {Link} from "react-router-dom";
import {wrap} from "../../common/common_utils";
import {VoucherTemplateItemType} from "../../common/VoucherTemplateItem";

declare const Stripe: any;

type VoucherPurchaseParams = {
    voucherId: string
}

export const VoucherPurchasePage = ({match}: RouteComponentProps<VoucherPurchaseParams>) => {
    const voucherAsync = useAsync(
        () => GlyftyApi.getVoucher(match.params.voucherId),
        [match.params.voucherId]
    );
    const {register, getValues, errors, handleSubmit, setValue, watch} = useForm();
    const asyncSubmit = useAsyncCallback(handleSubmit(enterStripePurchase));

    useEffect(() => {
        register({name: "email"}, {required: "You must enter a valid email", pattern: EMAIL_REGEX});
        register({name: "tandc"}, {required: "You must accept the terms and conditions"});
        register({name: "greeting"});
    }, [register]);

    if (voucherAsync.loading) {
        return <Loader content={"Loading"}/>
    }

    if (voucherAsync.error) {
        return <PublicLayout>
            <Message error>An error occurred: {voucherAsync.error.message}</Message>
        </PublicLayout>
    }

    const voucher = voucherAsync.result!;

    async function enterStripePurchase() {
        // const stripe = Stripe('pk_test_8oDhRr0hrthATt6wqfxWpfNV00ApJHVDIr'); // this is the connected account PK
        const {email, greeting} = getValues();
        return await GlyftyApi.createPurchaseSession(match.params.voucherId, email, greeting)
            .then((sessionInfo) => {
                // TODO: H: switch to non test key for production build
                const stripe = Stripe(STRIPE_PUBLIC_KEY /* glyfty public key, not merchant */, {
                    stripeAccount: sessionInfo.stripeAccountId
                });
                return stripe.redirectToCheckout({sessionId: sessionInfo.session.id})
                    .then(result => {
                        throw result.error;
                    });
            });
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    function handleTcChange(event, data) {
        const {name, checked} = data;
        setValue(name, checked ? true : undefined);
    }

    function describeError(error?: FieldError) {
        if (error) {
            return error.type === "pattern" ? "You must enter a valid email" : error.message;
        }
    }

    if (voucher.disabled) {
        return <PublicLayout>
            <Header className="voucher disabled">Sorry... this voucher is no longer available</Header>
        </PublicLayout>
    }

    const MAX_GREETING_LENGTH = 300;
    const greeting = watch("greeting");
    const greeting_length = greeting ? greeting.length : 0;
    const too_long = greeting_length > MAX_GREETING_LENGTH;

    const line_limit_exceeded = function () {
        if (!greeting) {
            return false;
        }
        if (!voucher.templateItems) {
            return false;
        }
        const greeting_spec = voucher.templateItems.find(i => i.type === VoucherTemplateItemType.GREETING);
        if (!greeting_spec) {
            return false;
        }
        const lines = wrap(greeting.split("\n"), greeting_spec.lineLength);
        return lines.length > 8;
    }();

    return <PublicLayout>
        <div className="voucher">
            <Image src={logoUrl(voucher.orgId)} height={230} centered/>
            <div><Header as='h2' className="voucher-title">
                {/*<Icon name='qrcode'/>*/}
                <Header.Content>
                    {voucher.title}
                </Header.Content>
            </Header></div>
            <Container>
                <div className="voucher-description">
                    {voucher.description_purchase}
                </div>
            </Container>

            <Statistic size={"tiny"}>
                <Statistic.Value className="voucher-price">
                    £{(voucher.cost / 100).toFixed(2)}
                </Statistic.Value>
                {/*
                <Statistic.Label>
                </Statistic.Label>
*/}
            </Statistic>
            <Form error={asyncSubmit.error !== undefined} warning={line_limit_exceeded} className="voucher-purchase-form">
                <Form.Input className="voucher-purchase-email" label="Please enter your email" type="text" name="email" placeholder='Email'
                            onChange={handleChange}
                            error={describeError(errors.email)}/>
                {voucher.templateId &&
                <>
                    <Form.Field control={TextArea} required name="greeting" label="Message to recipient"
                                rows={8} className="purchase-greeting"
                                placeholder='Your message'
                                onChange={handleChange}
                                error={describeError(errors.greeting)}/>
                    <div className={"greeting-length " + (too_long ? "greeting-length-exceeded" : "")}>{greeting_length} of {MAX_GREETING_LENGTH}</div>
                    {line_limit_exceeded &&
                    <Message warning>Your message contains too many lines and will not fit properly on the printable voucher</Message>}
                </>
                }
                <p><i>Be sure to enter a valid email &mdash; we will send the voucher to this address along with your payment receipt</i></p>
                <p>{expiryDate(voucher)}</p>
                <Form.Field>
                    <Checkbox label={<label>I agree to the <Link to="/tc">Terms and Conditions</Link></label>} name="tandc" onChange={handleTcChange}/>
                    {errors.tandc && <div><Label basic color="red" pointing prompt>{errors.tandc.message}</Label></div>}
                </Form.Field>
                {asyncSubmit.error && <Message error>{asyncSubmit.error.message}</Message>}

                <Button color="green" loading={asyncSubmit.loading} onClick={asyncSubmit.execute}>Purchase</Button>
            </Form>

            <PoweredBy/>

        </div>
    </PublicLayout>
};

