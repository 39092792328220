import * as React from "react";
import {useContext, useEffect} from "react";
import {RouteComponentProps} from "react-router";
import {OrgPageParams} from "../../components/OrgPageRouter";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {Form, InputOnChangeData, Message} from "semantic-ui-react";
import {useAsyncCallback} from "react-async-hook";
import {describeError, findAttribute} from "../../services/util";
import useForm from "react-hook-form";
import {GlyftyApi} from "../../services/GlyftyApi";

export const OrgDeletePage = ({match, history}: RouteComponentProps<OrgPageParams>) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const {orgId} = match.params;
    const email = findAttribute(authContext.attributes, "email");

    const {register, getValues, errors, handleSubmit, setValue} = useForm();
    const asyncSubmit = useAsyncCallback(handleSubmit(deleteOrg));

    useEffect(() => {
        register({name: "password"}, {required: "This field is required"});
    }, [register]);


    const org = authContext.organisations.find(o => o.id === match.params.orgId);
    if (!org) {
        return <Message error>Organisation does not exist. Perhaps it has been deleted?</Message>
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    async function deleteOrg() {
        const {password} = getValues();
        await authContext.actions.login(email, password);
        await GlyftyApi.deleteOrg(authContext, orgId);
        history.replace("/");
    }

    function cancel() {
        history.goBack();
    }

    return <>
        <Message warning header={"Delete " + org.name} content={<Message.Content>
            Are you really sure? Deleting this organisation means:
            <ul>
                <li>Any vouchers already purchased will become invalid</li>
                <li>All users will be removed from the organisation</li>
                <li>Voucher usage history will be lost (purchase information will remain in Stripe)</li>
            </ul>
            If you are sure you want to go ahead, please re-enter the password for <b>{email}</b>
        </Message.Content>}/>
        <Form success={asyncSubmit.status === "success"} error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
            <Form.Input label="Current Password" name="password" type="password" placeholder='Password' onChange={handleChange} error={describeError(errors.password)}/>

            {asyncSubmit.error && <Message error>{asyncSubmit.error.message}</Message>}

            <Form.Group>
                <Form.Button loading={asyncSubmit.loading} color="red">Confirm Delete</Form.Button>
                <Form.Button disabled={asyncSubmit.loading} type="cancel" onClick={cancel}>Cancel</Form.Button>
            </Form.Group>
        </Form>
    </>
};