import * as React from "react";
import {useContext, useState} from "react";
import {GlyftyApi} from "../../services/GlyftyApi";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {OrgPageParams} from "../../components/OrgPageRouter";
import {RouteComponentProps} from "react-router";
import {Button, Card, Label, Loader, Message} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {findAttribute} from "../../services/util";
import {Invite} from "../../common/Invite";
import {CapabilityGuard} from "../../components/CapabilityGuard";
import {Capability} from "../../common/Capability";
import {GlyftyConfirm} from "../../components/util/GlyftyConfirm";
import {useAsync, useAsyncCallback} from "react-async-hook";

type OrgUserInviteProps = {
    invite: Invite;
    onDelete(invites: Invite[]): void;
};

const OrgUserInvite = ({invite, onDelete}: OrgUserInviteProps) => {
    const authContext = useContext<AuthContextType>(AuthContext);

    const asyncSubmit = useAsyncCallback(deleteInvite);

    // TODO: handle error
    async function deleteInvite() {
        const invites = await GlyftyApi.deleteInvite(authContext, invite.orgId, invite.id);
        onDelete(invites);
    }

    return <Card>
        <Card.Content>
            <Card.Header className="invited-user">Pending</Card.Header>
            <Card.Meta>{invite.email}</Card.Meta>

            <Card.Description>
                {invite.capabilities.map((r, index) => <Label key={index} size="tiny">{r}</Label>)}
            </Card.Description>

            {asyncSubmit.error && <Message error content={asyncSubmit.error.message}/>}
        </Card.Content>
        <GlyftyConfirm loading={asyncSubmit.loading} basic icon="trash" prompt="Delete Invite" header={`Delete ${invite.email}`}
                       content={"Are you sure you want to delete this invite?"}
                       onOpen={asyncSubmit.reset} onConfirm={asyncSubmit.execute}/>
    </Card>
};

export const OrgUsersPage = ({match}: RouteComponentProps<OrgPageParams>) => {
    const {orgId} = match.params;
    const authContext = useContext<AuthContextType>(AuthContext);
    const [users, setUsers] = useState();
    const [invites, setInvites] = useState<Invite[]>([]);
    const asyncLoad = useAsync(load, [orgId]);

    async function load() {
        const invites = await GlyftyApi.getInvites(authContext, orgId);
        const users = await GlyftyApi.getUsers(authContext, orgId);
        setInvites(invites);
        setUsers(users);
    }

    if (asyncLoad.loading) {
        return <Loader active/>
    }

    if (asyncLoad.error) {
        return <Message error content={asyncLoad.error.message}/>
    }

    const email = findAttribute(authContext.attributes, "email", false);

    return <>
        <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_USERS]}>
            <p><Button as={Link} to={`/org/${orgId}/user/create`} style={{float: "right"}} color="green">Add User</Button></p>
        </CapabilityGuard>
        <Card.Group centered style={{clear: "both"}}>
            {
                invites.map((i, index) => <OrgUserInvite key={index} invite={i} onDelete={setInvites}/>)
            }
            {
                users.map((u, index) => <Card key={index}>
                    {email === u.email && <Label icon="user" corner color="teal" xsize="tiny"/>}
                    <Card.Content>
                        <Card.Header>{u.name}</Card.Header>
                        <Card.Meta>{u.email}</Card.Meta>

                        <Card.Description>
                            {u.capabilities.map((r, index) => <div key={index} className="capability-list"><Label size="tiny">{r}</Label></div>)}
                        </Card.Description>
                    </Card.Content>
                    <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_USERS]}>
                        <Button basic icon="edit" content="Edit" as={Link} to={`/org/${orgId}/user/edit/${u.userId}`}/>
                    </CapabilityGuard>
                </Card>)
            }
            {/*
        <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_USERS]}>
            <Card>
                <Card.Content className="new-voucher">
                    <Link to={`/org/${orgId}/user/create`}><NewUserIcon className="new-voc"/></Link>
                </Card.Content>
                <Button basic icon="plus square" as={Link} to={`/org/${orgId}/user/create`} content="Add User"/>
            </Card>
        </CapabilityGuard>
*/}
        </Card.Group></>
};