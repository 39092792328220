import * as React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Button, Form, Header, Input, InputOnChangeData, Label, Message, Segment} from "semantic-ui-react";
import {useDropzone} from "react-dropzone";
import {GlyftyApi} from "../../services/GlyftyApi";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import useForm from "react-hook-form";
import {UserOrganisation} from "../../common/UserOrganisation";
import {useAsyncCallback} from "react-async-hook";
import {CapabilityGuard} from "../../components/CapabilityGuard";
import {GlyftyConfirm} from "../../components/util/GlyftyConfirm";
import {describeError} from "../../services/util";
import {OrgPageParams} from "../../components/OrgPageRouter";

const OrgBasicSettings = (props: { org: UserOrganisation }) => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const {org} = props;

    const defaultValues = {name: org.name, email: org.email};
    const {register, getValues, errors, handleSubmit, setValue, reset: resetForm} = useForm({defaultValues});
    const asyncSubmit = useAsyncCallback(handleSubmit(updateOrg));

    useEffect(() => {
        register({name: "name"}, {required: "This field is required"});
        register({name: "email"}, {required: "This field is required"});
    }, [register]);

    async function updateOrg() {
        return GlyftyApi.updateOrganisation(authContext, org.id, getValues() as { name: string, email: string });
    }

    function reset() {
        setValue("name", org.name);
        setValue("email", org.email);
        resetForm();
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        const {name, value} = data;
        setValue(name, value);
    }

    return <Segment>
        <Label attached="top">Organisation Details</Label>
        <Form success={asyncSubmit.status === "success"} error={asyncSubmit.error !== undefined} onSubmit={asyncSubmit.execute}>
            <Form.Field control={Input} required name="name" label="Name" defaultValue={org.name}
                        placeholder='Name of the organisation' onChange={handleChange} error={describeError(errors.name)}/>

            <Form.Field control={Input} required name="email" label="Contact Email" defaultValue={org.email}
                        placeholder='Email for customers to reach you' onChange={handleChange} error={describeError(errors.email)}/>

            <Message success content="Updated successfully"/>
            {asyncSubmit.error && <Message error>{asyncSubmit.error.message}</Message>}

            <Form.Group>
                <Form.Button loading={asyncSubmit.loading} color="green">Update</Form.Button>
                <Form.Button disabled={asyncSubmit.loading} type="reset" onClick={reset}>Reset</Form.Button>
            </Form.Group>
        </Form>
    </Segment>

};

export const OrgSettingsPage = ({match, history}: RouteComponentProps<OrgPageParams>) => {
    const authContext = useContext<AuthContextType>(AuthContext);

    const org = authContext.organisations.find(o => o.id === match.params.orgId);
    if (!org) {
        console.log("Error finding org=", match.params.orgId, "in orgs=", authContext.organisations);
        throw new Error("Organisation not found");
    }

    const [uploadBusy, setUploadBusy] = useState();
    const [uploadSuccess, setUploadSuccess] = useState();

    const [file, setFile] = useState();
    const [preview, setPreview] = useState();
    const [imageSizeWarning, setImageSizeWarning] = useState();
    const [imageTypeError, setImageTypeError] = useState();

    const onDrop = useCallback(acceptedFiles => {
        const acceptedFile = acceptedFiles[0];
        console.log("File:", acceptedFile);
        if (acceptedFile.type === "image/png") {
            setImageTypeError(undefined);
            setFile(acceptedFile);
            setPreview(URL.createObjectURL(acceptedFile));
        } else {
            setImageTypeError("Logo must be a PNG image");
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false});

    const o = org;

    function upload() {
        GlyftyApi.createUploadUrl(authContext, o.id).then(r => {
            console.log("Uploading file:", file);
            setUploadBusy(true);
            setUploadSuccess(false);
            return fetch(r.signedRequest, {
                method: "PUT",
                headers: {
                    "Content-Type": "image/png",
                    "Cache-Control": "max-age=300"
                },
                body: file
            }).then(() => {
                setUploadSuccess(true);
                return r.url;
            }).finally(() => setUploadBusy(false))
        });
    }

    function deleteOrg() {
        history.push("delete");
    }

    function imageCheck(img) {
        if (img) {
            img.onload = function () {
                if (img.naturalWidth !== 220) {
                    setImageSizeWarning("Your logo should be 220 pixels wide and approximately 230 pixels high for best results");
                } else {
                    setImageSizeWarning(undefined);
                }
            }
        }
    }

    return <>
        <OrgBasicSettings org={org}/>

        <Segment>
            <Label attached="top">Logo</Label>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    preview ? <div><img ref={imageCheck} alt="preview" width={220} src={preview}/></div> : isDragActive ?
                        <div className="dropzone active">Drop logo here ...</div> :
                        <div className="dropzone">
                            <div>Click to choose image file or drop here</div>
                            <div><Button>Choose Image</Button></div>
                        </div>
                }
            </div>
            {imageTypeError && <Message error content={imageTypeError}/>}
            {uploadSuccess && <Message success>Image uploaded</Message>}
            {
                preview && <div>
                    <Button loading={uploadBusy} disabled={uploadBusy} onClick={upload}>Upload</Button>
                    {imageSizeWarning && <span>{imageSizeWarning}</span>}
                </div>
            }
        </Segment>
        <CapabilityGuard orgId={org.id} capabilities={[/* OWNER */]}>
            <Segment className="xdanger-zone" raised>
                <Label attached="top">Danger Zone</Label>
                <Header as='h5'>
                    <GlyftyConfirm loading={false} prompt="Delete" color="red" floated="right" header={"Remove " + org.name}
                                   content="Are you sure? This operation removes this organisation permanently and cannot be undone."
                                   onConfirm={deleteOrg}
                    />
                    <Header.Content>
                        Delete Organisation
                    </Header.Content>
                    <Header.Subheader>Delete organisation, all associated vouchers, purchases and users</Header.Subheader>
                </Header>
            </Segment>
        </CapabilityGuard>
    </>
};