import * as React from "react";
import {useContext} from "react";
import {RouteComponentProps} from "react-router";
import {OrgPageParams} from "../../components/OrgPageRouter";
import {AuthContext, AuthContextType} from "../../providers/AuthContextProvider";
import {CapabilityGuard} from "../../components/CapabilityGuard";
import {Capability} from "../../common/Capability";
import {GlyftyApi} from "../../services/GlyftyApi";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {Button, Card, Loader, Dropdown, Icon, Input, Modal, Message} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {ReactComponent as NewVoucherIcon} from "../../img/new_item.svg";
import {templateUrl} from "../../services/util";
import {VoucherTemplate} from "../../common/VoucherTemplate";
import {Voucher} from "../../common/Voucher";

type TemplateItemParams = {
    orgId: string;
    vouchers: Voucher[];
    template: VoucherTemplate;
    onTemplateDeleted: () => void;
};

type TemplateDeleteConfirmParams = {
    trigger: React.ReactNode;
    vouchers: Voucher[];
    onDelete: () => void;
}

const TemplateDeleteConfirm = ({vouchers, trigger, onDelete}: TemplateDeleteConfirmParams) => {
    const content = vouchers.length ? <Modal.Content>
        <p>You cannot delete this template because it is referenced by one or more vouchers:</p>
        <ul>
            {vouchers.map(v => <li key={v.id}>{v.title}</li>)}
        </ul>
    </Modal.Content> : "Are you sure you want to delete this template. Template image will be deleted and any template configuration will be lost";

    const actions: any[] = ["Cancel"];
    if (vouchers.length === 0) {
        actions.push({key: "ok", name: "ok", content: "OK", positive: true});
    }

    function click(e) {
        if (e.target.name === "ok") {
            onDelete();
        }
    }

    function stopPropogation(e) {
        e.stopPropagation();
    }

    return <Modal onClick={stopPropogation} onActionClick={click} centered trigger={trigger} header="Delete Template" content={content} actions={actions}/>
};

const TemplateItem = ({orgId, template, vouchers, onTemplateDeleted}: TemplateItemParams) => {
    const [renameMode, setRenameMode] = React.useState();
    const [name, setName] = React.useState(template.name);
    const authContext = React.useContext<AuthContextType>(AuthContext);
    const asyncSubmit = useAsyncCallback(save);
    const asyncDelete = useAsyncCallback(deleteTemplate);

    function updateName(e, data) {
        setName(data.value);
    }

    async function save() {
        const updated = {...template, name};
        await GlyftyApi.updateTemplate(authContext, updated);
        template.name = name;
        setRenameMode(false);
    }

    async function deleteTemplate() {
        await GlyftyApi.deleteTemplate(authContext, orgId, template.id);
        onTemplateDeleted();
    }

    function focus(el) {
        if (el) {
            el.focus();
        }
    }

    function edit() {
        setRenameMode(true);
    }

    function cancel() {
        setRenameMode(false);
        setName(template.name);
    }

    return <Card>
        <Card.Content><>
            {renameMode ||
            <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_TEMPLATES]}>
                <Dropdown icon="ellipsis vertical" className="voucher hamburger">
                    <Dropdown.Menu>
                        <Dropdown.Item icon="i cursor" content="Rename" onClick={edit}/>
                        <Dropdown.Item icon="edit" content="Configure Template Defaults" as={Link} to={`/org/${orgId}/template/configure/${template.id}`}/>
                        <TemplateDeleteConfirm onDelete={asyncDelete.execute} trigger={<Dropdown.Item icon="trash alternate outline" content="Delete"/>} vouchers={vouchers}/>
                    </Dropdown.Menu>
                </Dropdown>
            </CapabilityGuard>}

            {asyncSubmit.loading ? <Loader active={true}/> : <>
                <Card.Header>
                    {renameMode ? <>
                    <span style={{float: "right"}}>
                        <Icon name="check" color="green" size="small" onClick={asyncSubmit.execute}/>
                        <Icon name="close" color="red" size="small" onClick={cancel}/>
                    </span>
                        <Input className="inline-edit" ref={focus} transparent type="text" value={name} onChange={updateName}/>
                    </> : <>{template.name}</>}
                </Card.Header>
                <Card.Description><img alt="template" width="100%" src={templateUrl(orgId, template.id)}/></Card.Description>
            </>}
        </>
        </Card.Content>
    </Card>
};

export const TemplateListPage = ({match}: RouteComponentProps<OrgPageParams>) => {
    const authContext = useContext<AuthContextType>(AuthContext);

    const orgId = match.params.orgId;

    const asyncFetch = useAsync(async () => {
        return {
            templates: await GlyftyApi.getTemplates(authContext, orgId),
            vouchers: await GlyftyApi.getVouchers(authContext, orgId)
        }
    }, [orgId]);

    if (asyncFetch.loading) {
        return <Loader active={true}/>
    }

    if (asyncFetch.error) {
        return <Message error content={asyncFetch.error.message}/>
    }

    const {vouchers, templates} = asyncFetch.result!;

    const empty = templates.length === 0;

    return <>
        <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_ORGANISATION]}>
            {empty || <p><Button as={Link} to={`/org/${orgId}/template/create`} style={{float: "right"}} color="green">Add Template</Button></p>}
        </CapabilityGuard>

        <Card.Group centered style={{clear: "both"}}>
            {
                templates.map(r => <TemplateItem orgId={orgId} template={r} key={r.id} vouchers={vouchers.filter(v => v.templateId === r.id)}
                                                 onTemplateDeleted={asyncFetch.execute}/>)
            }
            {empty && <CapabilityGuard orgId={orgId} capabilities={[Capability.MODIFY_VOUCHERS]}>
                <Card>
                    <Card.Content className="new-voucher">
                        <Link to={`/org/${orgId}/template/create`}><NewVoucherIcon className="new-voc"/></Link>
                    </Card.Content>
                    <Button basic icon="plus square" as={Link} to={`/org/${orgId}/template/create`} content="Create New Template"/>
                </Card>
            </CapabilityGuard>}
        </Card.Group>
    </>
};